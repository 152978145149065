export default data => (
    data.map(hotel => ({
        showPrice: false,
        title: hotel.Titulo,
        link: hotel.Link,
        image: {
            alt: hotel.Imagen.ImgAlt,
            url: hotel.Imagen.UrlImagen
        }
    }))
)
const mapCertificates = items => 
    items
        .filter(n => n.imagen.activo)
        .sort((a,b) => a.orden - b.orden)
        .map(x => ({
            image: x.imagen.imagen.map(x => x.url),
            link: x.imagen.imagen[0].link,
            alt: x.imagen.imagen[0].alternativeText
        }))

const mapSocialMedia = items =>
    items
        .map(i => ({
            title: i.titulo,
            icon: i.icono,
            url: i.url
        }))

const mapFooterPcom = response => {
    if(!response || response?.layouts[0].length == 0) return null;

    const { textoFooter, itemsFooter, redesSociales } = response.layouts[0]

    return {
        text: textoFooter,
        certificates: mapCertificates(itemsFooter),
        socialMedia: mapSocialMedia(redesSociales)
    }
 }
 
 export default mapFooterPcom
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Icon from 'components/shared/icon';
import MenuMobile from 'components/header/menuMobile';

import { useApplication } from 'hooks/application';
import { trackClick } from 'hooks/tracking';
import useAxios from 'hooks/axios';

import urls from 'shared/urls';
import authenticatedUser from 'shared/authenticatedUser';
import cookie from 'shared/cookie';
import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default() => {
  useStyles(classes, globalStyles);
  const axios = useAxios();
	const { t } = useTranslation('myAccount');
  const { country } = useApplication();
  const isDesktop = useMediaQuery({ query: `(min-width:${breakpoints.md})`});
  
  const [menuAccount, setMenuAccount] = useState({
    isOpen: false,
    isLoggedIn: false,
    fullName: ''
  });

  const { isLoggedIn, isOpen, fullName } = menuAccount;

  useEffect(()=> {
    const { authenticated, name, surname } = authenticatedUser();
    const username = name && surname ? `${name} ${surname}` : '';

    setMenuAccount((prevState) => ({
      ...prevState,
      isLoggedIn:authenticated,
      fullName:username
    }));
  }, []);

  const logoutClick = async event => {
    event.preventDefault();
    const url = `${urls.domain}.${country.url}${t('logoutLink')}`;
    await axios.get(url);
    cookie.set('login', false);
    cookie.delete('USERNAME');

    setMenuAccount(prevState => ({
      ...prevState,
      isLoggedIn: false,
      isOpen: false
    }));
  }

  const handleMenuOpen = () => setMenuAccount(prevState => ({ ...prevState, isOpen:!prevState.isOpen }));

  const handleClickMyAccount = () => {
    trackClick('Mi Cuenta home')
    window.location.href = t('myAccountLink');
  }

  const Links = () => (
    <>
      <li>
        <a href={t('myDataLink')}> { t('myData') } </a>
      </li>
      <li>
        <a href={t('myPurchasesLink')}> { t('myPurchases') } </a>
      </li>
      <li>
        <a onClick={logoutClick} href=''> { t('logout') } </a>
      </li>
    </>
  );

  const MobileMenu = (
    <>
      <button 
        data-testid='menu-mobile'
        aria-label='Login'
        className={`${classes.button} ${globalStyles.flexAlignCenter}`}
        onClick={!isLoggedIn ? handleClickMyAccount : handleMenuOpen} 
      >
        <Icon classes={classes.icon} name='user' />
        <span className={classes.caret} />
      </button>
      { 
        isOpen &&
          <MenuMobile>
            <Links />
          </MenuMobile>
      }
    </>
  );

  const DesktopMenu = (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <Icon classes={classes.icon} name='user' />
      </div>
      <button 
        data-testid='menu-logged' 
        aria-label='menu options'
        className={classes.button}
        onClick={isLoggedIn ? handleMenuOpen : handleClickMyAccount} 
      >
        {
          isLoggedIn
            ? <>
                <div className={classes.fullName}>
                  <span className={classes.username}>
                    { fullName ? fullName : t('welcome') }
                  </span>
                  <span className={classes.caret} />
                </div>
                { 
                  isOpen &&
                    <div data-testid='menu-options' className={classes.items}>
                      <ul>
                        <Links />
                      </ul>
                    </div>
                }
              </>
            : <span className={classes.username}> { t('myAccount') } </span>
        }
      </button>
    </div> 
  )
  
  return (
      <div>
        { 
          isDesktop
            ? DesktopMenu 
            : MobileMenu 
        }
      </div>        
  );
}
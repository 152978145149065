export default data => (
    data.ImagenesCertificados.map(img => {
      return {
          item:{
              image: img.Item.Imagen,
              link: img.Item.Link,
              alt: img.Item.AltImagen
          }
      }
    })
);
import React,{ useState,useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Image from 'components/shared/image';
import Country from 'components/header/leftContainer/country';
import Currency from 'components/header/leftContainer/currency';

import layoutContext from 'hooks/layout/layoutContext';
import { useApplication } from 'hooks/application';

import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import moment from 'moment';
import './translations';

export default() => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation('leftContainer');
    const layoutContent = useContext(layoutContext);
    const { features, culture, country, activeCurrency, logo, host } = useApplication();
    const [ logoHeader, setLogoHeader ] = useState();
    const activeCurrencyCode = features.enableRealCurrencySymbol ? activeCurrency.codeShow : activeCurrency.code; 
    const baseURL =`https://${host}`;

    useEffect(()=>{
        const getLogo = async()=>{
            const { url,title,alt } = layoutContent?.logo || logo;
            setLogoHeader({
                url,
                title,
                alt
            })
        };
      
        getLogo();
    },[layoutContent])

    const isDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.md})`});

    const
        date = moment().locale(culture.iso_code),
        day = date.date(),
        year = date.format('YYYY').toUpperCase(),
        monthName = date.format('MMM').toUpperCase(),
        dayName = date.format('dddd').toUpperCase();

    const dayClasses = `${classes.day} ${classes.color} ${globalStyles.fwBold}`;
    const fontGrey = `${globalStyles.fs12} ${classes.color}`;
    const fontGreyWeightUpper = `${globalStyles.fs12} ${classes.color} ${globalStyles.fwBold} ${globalStyles.textUppercase}`;

    const logoImage = isDesktop ? 'https://p10-cdn.s3.amazonaws.com/contenido/puntocom/argentina/logo-ar/p10-logo-desktop.svg' : 'https://p10-cdn.s3.amazonaws.com/contenido/puntocom/argentina/logo-ar/p10-logo-mobile.svg';

    return (
        <div className={classes.logoAndDropdownsContainer}>
            <div>
            <a href={baseURL}> 
                {
                logoHeader &&
                <Image image={logoImage} alt={logoHeader.alt} title={logoHeader.title} customClasses={classes.logo} />
                } 
            </a>
            </div>
            {
                isDesktop &&
                    <div className={`${globalStyles.flexColumn} ${globalStyles.flexJustifyCenter}`}>
                        <div className={`${classes.dropdownsContainer} ${globalStyles.flex}`} >
                            <Country />
                            { features.enableCurrency && <Currency /> }
                        </div>
                        <div className={classes.divider} />
                        <div className={globalStyles.flexAlignCenter}>
                            {
                                features.enableCurrency && country.currencyCode !== activeCurrency.code
                                    ? <div className={`${classes.estimatedQuotation} ${globalStyles.flexColumn}`}>
                                        <span className={fontGreyWeightUpper}> { t('estimated_quotation') } </span>
                                        <span className={fontGreyWeightUpper}> 
                                            { `1 ${activeCurrencyCode} ≈ ${country.currencyCode} ${(1 / activeCurrency.value).toFixed(2)}` }
                                        </span>
                                      </div>
                                    : <>
                                        <span className={dayClasses}> { day } </span>
                                        <div className={globalStyles.flexColumn}>
                                            <span className={fontGrey}> { dayName } </span>
                                            <span className={fontGrey}> { monthName } / { year } </span>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
            }
        </div>
    )
}
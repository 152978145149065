import React, { useEffect } from 'react';
import { Context as ResponsiveContext } from 'react-responsive'
import { useTranslation } from 'react-i18next';

import { useApplication } from 'hooks/application';
import { useError } from 'hooks/error';
import '../translations';

import Error from 'components/shared/error';

import Routes from '../routes';
import cookie from 'shared/cookie';
import device, { sizes } from 'shared/device';
import breakpoints from 'shared/breakpoints';

import './index.css';

export default ({ userAgent }) => {
	const { i18n } = useTranslation();
	const { culture } = useApplication();
	const { error } = useError();
	const deviceName = device(userAgent).name;

	useEffect(() => {
		// TODO: Cuando se cambie i18n por la nueva version, ver si se puede extraer esto de aca.
		i18n.changeLanguage(culture.iso_code);
		cookie.set('Cultura', culture.iso_code, 365);
	}, []);

	return (
		<ResponsiveContext.Provider value={{ width: breakpoints[sizes[deviceName]] }}>
			<Routes />
			
			{ error && <Error data-testid='error' /> }
		</ResponsiveContext.Provider>
	);
}
export default data => {
    const { banner } = data;

    const image = banner.imagen[0];

    return {
        image: banner.imagen.map(i => i.url),
        title: image.caption,
        alt: image.alternativeText,
        link: banner.link
    }

}
import isServer from 'shared/isServer';

class Cookie {
    get(key) {
        const match = !isServer() && document.cookie.match(new RegExp('(^| )' + key + '=([^;]+)'));
        if (match) return match[2];
    }

    set(key, value, days) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = date.toUTCString();
        }
        document.cookie = typeof value === 'object'
            ? `${key}=${JSON.stringify(value)} ;expires=${expires}; path=/`
            : `${key}=${value} ;expires=${expires}; path=/`;
    }

    set(key, value, days, domain) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = date.toUTCString();
        }
        document.cookie = typeof value === 'object'
            ? `${key}=${JSON.stringify(value)} ;expires=${expires}; path=/`
            : `${key}=${value} ;expires=${expires}; domain=${domain}; path=/`;
    }

    delete(key) {
        this.set(key, '', -1);
    }

    update(key, value, days) {
        this.delete(key);
        this.set(key, value, days);
    }

    deleteAll(){
        var cookies = document.cookie.split("; ");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var igualPos = cookie.indexOf("=");
            var name = igualPos > -1 ? cookie.substring(0, igualPos) : cookie;
            document.cookie = name + "=; expires=Thu, 17 Aug 2023 00:00:00 UTC; path=/;";
        }
    }

}

export default new Cookie();
import mapWhatIsP10 from './mappers/mapWhatIsP10';
import mapLanding from './mappers/mapLanding';
import mapMedia from './mappers/mapMedia';
import mapJobs from './mappers/mapJobs';
import mapGeneralConditions from './mappers/mapGeneralConditions';
import mapQualityManagement from './mappers/mapQualityManagement';
import mapTransportCompanies from './mappers/mapTransportCompanies';
import mapTerminals from './mappers/mapTerminals';
import mapHelp from './mappers/mapHelp';
import mapCompanies from './mappers/mapCompanies';
import mapSpecialJourneys from './mappers/mapSpecialJourneys';
import mapJoinOurNet from './mappers/mapJoinOurNet';
import mapHowToBuy from './mappers/mapHowToBuy';
import mapHowToBuyElectronicPurchase from './mappers/mapHowToBuyElectronicPurchase';
import mapHowToBuyMobilePurchase from './mappers/mapHowToBuyMobilePurchase';
import mapHowToBuyPersonalPurchase from './mappers/mapHowToBuyPersonalPurchase';
import mapStops from './mappers/mapStops';
import mapTerminal from './mappers/mapTerminal';
import mapGenericMessage from './mappers/mapGenericMessage';
import mapSocialMedia from './mappers/mapSocialMedia';
import mapHotels from './mappers/mapHotels';
import mapDeals from './mappers/mapDeals';
import mapCertificates from './mappers/mapCertificates';
import mapRoutes from './mappers/mapRoutes';
import mapFeaturedDeal from './mappers/mapFeaturedDeal';
import mapDeal from './mappers/mapDeal';
import mapMostSearchedTickets from './mappers/mapMostSearchedTickets';

import { useApplication } from 'hooks/application';
import useAxios from 'hooks/axios';
import mapTerminalComments from './mappers/mapTerminalComments';
import Axios from 'axios';

const useContent = () => {
    const axios = useAxios();
    const { idPv, culture:{ iso_code: culture }, landingName, country:{ url: country }, name, cms, typeApplication } = useApplication();
    const { isOW, isPcom } = typeApplication;

    const getLanding = async () => {
        const { data } = await axios
            .get(`ApiLandings/Obtener?idPV=${idPv}&lang=${culture}&nombre=${landingName}&pais=${country}`);
            
        return mapLanding(data);
    }
    
    const getWhatIsP10 = async () => {
        const { data } = await axios.get(`Contenido/queEsP10?idPv=${idPv}&lang=${culture}`);
        return mapWhatIsP10(data);
    }

    const getMedia = async () => {
        const { data } = await axios.get(`Contenido/Medios?idPv=${idPv}&lang=${culture}`);
        return mapMedia(data);
    }

    const getJobs = async () => {
        const { data } = await axios.get(`Contenido/Empleos?idPv=${idPv}&lang=${culture}`);
        return mapJobs(data);
    }

    const getGeneralConditions = async () => {
        const { data } = await axios
            .get(`Contenido/CondicionesGenerales?idPv=${idPv}&lang=${culture}&nombre=${name}&contenidoCMS=${cms}&pais=${country}`);
        
        return mapGeneralConditions(data);
    }

    const getQualityManagement = async () => {
        const { data } = await axios.get(`Contenido/GestionDeCalidad?idPv=${idPv}&lang=${culture}`);
        return mapQualityManagement(data);
    }

    const getTransportCompanies = async () => {
        const { data } = await axios
            .get(`Contenido/EmpresasDeTransporte?idPv=${idPv}&lang=${culture}&nombre=${name}&contenidoCMS=${cms}&pais=${country}`);
        
        return mapTransportCompanies(data);
    }

    const getTerminals = async () => {
        const { data } = await axios.get(`Contenido/Terminales?idPv=${idPv}&lang=${culture}`);
        return mapTerminals(data);
    }

    const getHelp = async () => {
        const help = await axios
            .get(`Contenido/Ayuda?idPv=${idPv}&lang=${culture}&nombre=${name}&contenidoCMS=${cms}&pais=${country}`)
        
        const questions = await axios
            .get(`Contenido/PreguntasFrecuentes?idPv=${idPv}&lang=${culture}&nombre=${name}&contenidoCMS=${cms}&pais=${country}`)

        return mapHelp(help.data, questions.data);
    }

    const getCompanies = async () => {
        const { data } = await axios.get(`Contenido/Empresas?idPv=${idPv}&pais=${country}&lang=${culture}`);
        return mapCompanies(data);
    }
    
    const getSpecialJourneys = async () => {
        const { data } = await axios.get(`Contenido/ObtenerViajesEspeciales?idPv=${idPv}&lang=${culture}`);
        return mapSpecialJourneys(data);
    }

    const getJoinOurNet = async () => {
        const { data } = await axios.get(`Contenido/AdhesionIndex?idPv=${idPv}&lang=${culture}`);
        return mapJoinOurNet(data);
    }

    const getHowToBuy = async () => {
        const { data } = await axios.get(`Contenido/ComoComprar?idPv=${idPv}&lang=${culture}`);
        return mapHowToBuy(data);
    }

    const getHowToBuyElectronicPurchase = async () => {
        const { data } = await axios
            .get(`Contenido/CompraElectronica?idPv=${idPv}&lang=${culture}&nombre=${name}&contenidoCMS=${cms}&pais=${country}`);
        
        return mapHowToBuyElectronicPurchase(data);
    }

    const getHowToBuyMobilePurchase = async () => {
        const { data } = await axios
            .get(`Contenido/CompraMobile?idPv=${idPv}&lang=${culture}&nombre=${name}&contenidoCMS=${cms}&pais=${country}`);
        
        return mapHowToBuyMobilePurchase(data);
    }
    
    const getHowToBuyPersonalPurchase = async (id, city) => {
        const { data } = await axios
            .get(`Contenido/CompraPresencial?idProvincia=${id}&nombreProvincia=${city}&lang=${culture}&pais=${country}&idPV=${idPv}`);
        
        return mapHowToBuyPersonalPurchase(data);
    }
    
    const getBusStops = async () => {
        const { data } = await axios.get('ApiGeoInformacion/GenerarParadasBuscadorBus')
        return mapStops(data);
    }

    const getTerminal = async terminalName => {
        const { data } = await axios.get(`Contenido/Terminal?idPv=${idPv}&lang=${culture}&nombre=${terminalName}`);
        return mapTerminal(data);
    }
    const getTerminalComments = async terminalName => {
        const { data } = await axios.get(`Contenido/Terminal?idPv=${idPv}&lang=${culture}&nombre=${terminalName}`);
        return mapTerminalComments(data);
    }

    const getWhatIsP10Video = async () => {
        const { data } = await axios.get(`Buses/QueEsP10Video?idPv=${idPv}&lang=${culture}&pais=${country}`);
        return data.UrlVideo;
    }

    const getHowToBuyVideo = async () => {
        const { data } = await axios.get(`Buses/VideoComoComprarPasajes?idPv=${idPv}&lang=${culture}&pais=${country}`);
        return data.UrlVideo;
    }
    
    const getGenericMessage = async endpoint => {
        const { data } = await axios.get(`${endpoint}?idPv=${idPv}&nombre=${name}&pais=${country}`);
        return mapGenericMessage(data);
    }

    const getSocialMedia = async () => {
        const { data } = await axios.get(`Contenido/RedesSociales?idPv=${idPv}`);
        return mapSocialMedia(data);
    }

    const getHotelsMetadata = async () => {
        const { data } = await axios.get(`ApiHoteles/ObtenerMetadataHoteles?idPv=${idPv}&lang=${culture}&pais=${country}`);
        return data ? data.Metadata : {};
    }

    const getHotels = async () => {
        const { data } = await axios.get(`ApiHoteles/PromocionesHoteles?idPv=${idPv}&lang=${culture}`);
        return mapHotels(data);
    }

    const getFlightsMetadata = async () => {
        const { data } = await axios.get(`ApiVuelos/ObtenerMetadataVuelos?idPv=${idPv}&lang=${culture}`);
        return data ? data.Metadata : {};
    }

    const getDeals = async () => {
        const { data } = await axios
            .get(`Contenido/Promociones?idPv=${idPv}&lang=${culture}&pais=${country}&nombreAplicacion=${name}`);
        return mapDeals(data);
    }

    const getCabsMetadata = async () => {
        const { data } = await axios.get(`ApiRemises/ObtenerMetadata?idPv=${idPv}&lang=${culture}&pais=${country}`);
        return data ? data : {};
    }

    const getFerriesMetadata = async () => {
        const { data } = await axios.get(`ApiFluviales/ObtenerMetadata?idPv=${idPv}&lang=${culture}&pais=${country}`);
        return data ? data : {};
    }

    const getCertificates = async () => {
        const { data } = await axios.get(`ApiFooter/ObtenerFooter?idPv=${idPv}&lang=${culture}&pais=${country}`);
        return mapCertificates(data);
    }
    
    const getRoutes = async (requestKey, serviceIndex) => {
        const { data } = await Axios
            .get(`ApiServicios/MostrarRecorrido?claveSolicitud=${requestKey}&indiceServicio=${serviceIndex}`);
        return mapRoutes(data);
    }

    const getFeaturedTransfer = async () => {
        const { data } = await axios
            .get(`ApiCircuitosTraslados/TransferDestacado?idPv=${idPv}&lang=${culture}&pais=${country}`)

        return mapFeaturedDeal(data);
    }

    const getFeaturedCircuit = async () => {
        const { data } = await axios
            .get(`ApiCircuitosTraslados/CircuitoDestacado?idPv=${idPv}&lang=${culture}&pais=${country}`)
        
        return mapFeaturedDeal(data);
    }

    const getDealsTransfer = async () => {
        const { data } = await axios
            .get(`ApiCircuitosTraslados/Transfers?idPv=${idPv}&lang=${culture}&pais=${country}`)

        return data.map(mapDeal)
    }

    const getDealsCircuit = async () => {
        const { data } = await axios
            .get(`ApiCircuitosTraslados/Circuitos?idPv=${idPv}&lang=${culture}&pais=${country}`)

        return data.map(mapDeal)
    }

    const getMostSearchedTickets = async() => {
        const { data } = await axios.get(`ApiBuses/ObtenerPasajesMasBuscados?nombre=${name}&lang=${culture}&pais=${country}`)
        if(isOW || isPcom)
            return mapMostSearchedTickets(data.Result);
        else
            return mapMostSearchedTickets(data);
    }
  
    return {
        getLanding,
        getWhatIsP10,
        getMedia,
        getJobs,
        getGeneralConditions,
        getQualityManagement,
        getTransportCompanies,
        getTerminals,
        getHelp,
        getCompanies,
        getSpecialJourneys,
        getJoinOurNet,
        getHowToBuy,
        getHowToBuyElectronicPurchase,
        getHowToBuyMobilePurchase,
        getHowToBuyPersonalPurchase,
        getBusStops,
        getTerminal,
        getWhatIsP10Video,
        getHowToBuyVideo,
        getGenericMessage,
        getSocialMedia,
        getHotelsMetadata,
        getHotels,
        getFlightsMetadata,
        getDeals,
        getCabsMetadata,
        getFerriesMetadata,
        getCertificates,
        getRoutes,
        getFeaturedTransfer,
        getFeaturedCircuit,
        getDealsTransfer,
        getDealsCircuit,
        getMostSearchedTickets,
        getTerminalComments
    }
}

export default useContent;
const mapNews = news => {
    return news.map(item => {
        const { DescripcionNota, ImgAlt, LinkNota, TextoLinkNota, TituloNota, UrlImagen } = item;

        return {
            title: TituloNota,
            description: DescripcionNota,
            imgAlt: ImgAlt,
            link: LinkNota,
            linkText: TextoLinkNota,
            imgUrl: UrlImagen
        }
    })
}

const mapData = data  => {
    const { Titulo, Notas, Metadata } = data;
    
    return {
        title: Titulo,
        news: mapNews(Notas),
        metadata: Metadata
    }
}

export default mapData;

import React from 'react';

import Image from 'components/shared/image';

import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default({ landing }) => {
    useStyles(classes);
    const { logo } = useApplication();

    return (
        <div className={classes.container}>
            <Image 
                image={landing.logo} 
                alt={landing.description.name}
                title={landing.description.name}
                customClasses={classes.companyLogo} 
            />
            <Image 
                image={logo.imgUrl} 
                alt={logo.altImg} 
                title={logo.titleImg} 
                customClasses={classes.pcomLogo} 
            />
        </div>
    )
}
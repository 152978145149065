import React from 'react'

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import PropTypes from 'prop-types';

const Loading = ({ height, width }) => {
  useStyles(classes, globalStyles);
  
  return (
    <div className={globalStyles.flexCenter} style={{ height, width }}>
      <div className={classes.loader} />
    </div>
  )
}

Loading.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

Loading.defaultProps = {
  height: '200px',
  width:'100%'
};

export default Loading;
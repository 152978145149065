import mapBanner from './mapBanner';
import mapSlides from './mapSlides';
import mapSeoContent from './mapSeoContent';
import mapMetaData from './mapMetaData';
import getFirst from './getFirst';

const mapHomeBuses = response => {
    const content = getFirst(response, 'homBuses');
    return  content ? getHomeBusesContent(content) : null;
}

const getHomeBusesContent = data =>{
    const banner = mapBanner(data)
    const metadata = mapMetaData(data.metadata);
    const slides = mapSlides(data)
    const seoContent = mapSeoContent(data)

    return {
        slides,
        banner,
        metadata,
        seoContent,
        linkCashPurchase: data.linkCompraEfectivo
    }
}

export default mapHomeBuses
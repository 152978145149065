import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import useAxios from 'hooks/axios';
import Header from 'components/landings/header';
import Loading from 'components/shared/loading';
import JsonLd from 'components/shared/comments/jsonLd';

import useContent from 'hooks/content';
import { useApplication } from 'hooks/application';
import useRedis from 'hooks/cms/redis';
import { mapLandingLayout,mapComments } from '/hooks/cms/mappers/mapLanding';

import useStyles from 'isomorphic-style-loader/useStyles'
import classes from 'components/style.module.scss';

export default ({ children }) => {
    const { landingName,idPv,country } = useApplication();
    const apli= useApplication();
    const { getCache } = useRedis();
    const axios = useAxios();
    useStyles(classes);
    const { getLanding } = useContent();

    const [data, setData] = useState();

    useEffect(() => {
        const getData = async () => {
            const landingStrapi = await getCache({ key:'landingLayoutQuery',link:landingName,idPv:idPv });
            const strapiContent = await mapLandingLayout(landingStrapi);            

            await isNotStrapi(strapiContent);
        }

        getData();
    }, []);

    const getComments = async (country) => {
        const { data } = await axios.get(`ApiLandings/ObtenerComentarios?nombre=${landingName}&pais=${country}`);
        return data;
    };

    const isNotStrapi = async(strapiContent)=>{
    if(strapiContent){
        if(strapiContent.landing.showRating){
            const comments = await getComments(country.url);

            strapiContent.comments  = mapComments(comments);
        }
        const jsonLd = JsonLd.generateRating(strapiContent);

        const content = { landingData:strapiContent, jsonLd };

    setData(content);
    }else{
        const landingN2 = await getLanding();

        if (!landingN2.landing.active) window.location = landingN2.landing.redirectionUrl;
        
        const jsonLd = JsonLd.generateRating(landingN2);
        
        const content = { landingData:landingN2, jsonLd };
    setData(content);
    };
}
    return data
        ? <>
            <Helmet>
                {
                    data.jsonLd &&
                        <script type='application/ld+json'>
                            { data.jsonLd }
                        </script>
                }
                <style>{`
                    :root {
                        --primary-main: ${data.landingData.styles.searchBoxBgColor || '#ff7731'};
                        --orange-main: #ff7731;
                        --grey-light: #ebebeb;
                        --grey-dark: #999;
                        --searchBox-font-color: ${data.landingData.styles.searchBoxFontColor || '#fff'};
                        --searchBox-button-font-color: ${data.landingData.styles.searchBoxButtonFontColor || '#fff'};
                        --searchBox-button-bg-color: ${data.landingData.styles.searchBoxButtonBgColor};
                        --searchBox-button-font-color-hover: #000;
                        --searchBox-button-bg-color-hover: ${data.landingData.styles.searchBoxButtonBgColor};
                        --destination-title-item-bg-color: ${data.landingData.styles.destinationTitleItemBgColor};
                    }
                `}
              </style>
            </Helmet>
            
            <Header landing={data.landingData.landing} />
            <div className={classes.container}>
                { children }
            </div>
          </>
        : <Loading />
}

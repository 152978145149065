import useAxios from 'hooks/axios';
const useRedis = () => {
  const axios = useAxios();

    const getCache = async (params) =>{
        const url =`${process.env.REDIS_URL_PRIVATE}/getContent`;  
        
        const { data } = await axios.post(url,params);
      
        return data;
      }

    return{
    getCache
  }
}

export default useRedis;
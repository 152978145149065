// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._06ad4aefb4cd422b92b22a06b1a0f55d-scss{margin:1rem}._63777d677b1bfa3b6fe38bddb1a8767f-scss,._63777d677b1bfa3b6fe38bddb1a8767f-scss:after{border-radius:50%;width:10em;height:10em}._63777d677b1bfa3b6fe38bddb1a8767f-scss{margin:60px auto;font-size:5px;position:relative;text-indent:-9999em;border-top:1.1em solid rgba(189,189,189,0.2);border-right:1.1em solid rgba(189,189,189,0.2);border-bottom:1.1em solid rgba(189,189,189,0.2);border-left:1.1em solid var(--primary-main);transform:translateZ(0);-webkit-animation:f1acbd8247bc85424c5fa4f8ef02693e-scss 1.1s infinite linear;animation:f1acbd8247bc85424c5fa4f8ef02693e-scss 1.1s infinite linear}@-webkit-keyframes f1acbd8247bc85424c5fa4f8ef02693e-scss{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@keyframes f1acbd8247bc85424c5fa4f8ef02693e-scss{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}\n", ""]);
// Exports
exports.locals = {
	"progress": "_06ad4aefb4cd422b92b22a06b1a0f55d-scss",
	"loader": "_63777d677b1bfa3b6fe38bddb1a8767f-scss",
	"load8": "f1acbd8247bc85424c5fa4f8ef02693e-scss"
};
module.exports = exports;

const mapSeoTextBanners = data=> {
    if(!data) return null;

    return data
        .filter(i => i.activo)
        .map(x => (
            {
                title: x.titulo,
                text: x.texto
            }
        ))
}

export default mapSeoTextBanners
const mapMostSearchedTickets = tickets => {
    return tickets.map(t => {
        const { imagen,
            precio,
            cantidadDiasPosteriorFechaIda,
            esBanner,
            padDestino,
            padOrigen,
            idPadDestino,
            idPadOrigen } = t;

        return {
            image: imagen?.imagen.map(i => i.url),
            altImage: imagen?.imagen[0]?.alternativeText,
            price: precio,
            idOriginPad:idPadOrigen,
            idDestinationPad: idPadDestino,
            originPad: padOrigen,
            destinationPad: padDestino,
            isBanner: esBanner,
            bannerLink:  imagen.link,
            date: Date.now(),
            daysAfterDepartureDate: cantidadDiasPosteriorFechaIda,
        }
    })
}

export default mapMostSearchedTickets;
const mapDeals = deals => (
    deals.map(deal => {
        const { Activo, DescripcionListado, ImagenListado, Tipo, Url } = deal;

        return {
            url: Url,
            type: Tipo,
            active: Activo,
            listImage: ImagenListado,
            listDescription: DescripcionListado,
        }
    })
);

export default data => {
    const { ListaPromociones, Metadata } = data;
    
    return {
        deals: mapDeals(ListaPromociones),
        metadata: Metadata
    }
};
export default data => (
    data.map(item => ({
      image: item.Imagen,
      altImage: item.AltImagen,
      bannerLink: item.BannerLink,
      isBanner: item.EsBanner,
      originPad: item.PadOrigen,
      idOriginPad: item.IdPadOrigen,
      destinationPad: item.PadDestino,
      idDestinationPad: item.IdPadDestino,
    }))
);
const mapLogo = data =>{
    if(!data || data.length == 0) return null;
    
    const { logoHeader } = data.layouts[0];
    if(!data) return null;
    
    return{
        url:logoHeader?.url,
        alt:logoHeader?.alternativeText,
        title:logoHeader?.caption
    }
}

export default mapLogo;
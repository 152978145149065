export default {
    myAccount:'Mi cuenta',
    myData:'Mis datos',
    myPurchases:'Mis compras',
    logout:'Salir',
    myDataLink:'/verperfil',
    myPurchasesLink:'/usuario/miscompras',
    logoutLink:'/autenticacion/logout',
    myAccountLink:'/login',
    welcome: 'Bienvenido '
}
const mapTerminals = terminal => {
    const { Nombre, Direccion, Ciudad, Telefono, ImagenTerminal, IdPad, Text, Latitud, Longitud } = terminal;
    const replaceSign = coordinate => String(coordinate).replace(/,/g, '.');

    return {
        name: Nombre,
        address: Direccion,
        city: Ciudad,
        phone: Telefono,
        image: ImagenTerminal,
        padId: IdPad,
        text: Text,
        latitude: parseFloat(replaceSign(Latitud)),
        longitude: parseFloat(replaceSign(Longitud))
    }
}

const mapComments = comments => {
    return comments.map(comment => {
        const { Usuario, Fecha, Puntaje, Comentario } = comment;

        return {
            username: Usuario,
            date: Fecha,
            rating: Puntaje,
            text: Comentario
        }
    })
}

export default ({ Terminal, Comentarios }) => {
    return {
        terminal: mapTerminals(Terminal),
        comments: mapComments(Comentarios),
        metadata: Terminal.Metadata
    }
};
import React, {createContext,useState,useEffect} from 'react';

import mapHomeBuses from 'hooks/cms/mappers/mapHomeBuses';
import mapWaysToBuy from 'hooks/cms/mappers/mapWaysToBuy';
import mapSeoTextBanners from 'hooks/cms/mappers/mapSeoTextBanners';
import mapNewsSection from 'hooks/cms/mappers/mapNewsSection';

import useRedis from 'hooks/cms/redis';
import { useApplication } from 'hooks/application';

const HomeBusesContext = createContext({});

const HomeBusesProvider  = ({children}) => {
    const {getCache} = useRedis();
    const {idPv} = useApplication();
    const [data, setData] = useState({});
    
    const getContent = async ()=>{
        const cmsContent = await getCache({key:'homeBusesQuery',idPv:idPv});
        const { cajasSeo } = cmsContent.homBuses[0];
        const bannersSliderYMetadata = mapHomeBuses(cmsContent);

        const content = {
                bannersSliderYMetadata,
                waysToBuy:mapWaysToBuy(cmsContent),
                seoTextBanner:process.env.FT_ENABLE_STRAPI_SEO_TEXT_BANNER? mapSeoTextBanners(cajasSeo): null,
                informativeContent: mapNewsSection(cmsContent),
                seoContent: bannersSliderYMetadata.seoContent
            };
        setData(content)
    }

    useEffect(()=> {
        if(process.env.FT_ENABLE_STRAPI_CMS) getContent()
    },[])

    return (
        <HomeBusesContext.Provider value={data}>
            {children}
        </HomeBusesContext.Provider>
    )
}

export default HomeBusesContext;

export {HomeBusesProvider};
import React from 'react';
import { useTranslation } from 'react-i18next';

import LeftContainer from 'components/landings/header/leftContainer';
import Navbar from 'components/header/navbar';

import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ landing }) => {
    useStyles(classes, globalStyles);
	const { t } = useTranslation('landingHeader');
	const { country } = useApplication();

	const howToBuy = country.url === 'ar' ? t('howtoShopLink') : t('electronicLink');

	return (
        <div className={classes.root}>
            <div className={classes.leftContainer}>
                <LeftContainer landing={landing}/>
            </div>
            <div className={`${classes.rightContainer} ${globalStyles.flexAlignCenter}`} >
                <Navbar>
                    <li>
                        <a className={classes.link} href={`/${t('coverPageLink')}`}> {t('coverPage')}</a>
                    </li>
                    <li>
                        <a className={classes.link} href={`/${howToBuy}`}> {t('howtoShop')}</a>
                    </li>
                    <li>
                        <a className={classes.link} href={`/${t('whatIsPlataforma10Link')}`}> {t('whatIsPlataforma10')}</a>
                    </li>
                    <li>
                        <a className={classes.link} href={`/${t('helpLink')}`}> {t('help')}</a>
                    </li>
                </Navbar>
            </div>
        </div>
	);
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookie from '../../../shared/cookie';

import Button from 'components/shared/button';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

const ModalCookies = ({showPrivacyPolicy}) => {
  const { t } = useTranslation('modalCookies');
  useStyles(classes, globalStyles);
  const [show, setShow] = useState(showPrivacyPolicy); 
  
  const handleClick = (newValue) => {
    setShow(newValue); 
    if (newValue){
      cookie.set('privacyPolicy',true)
    }
    }; 
  
    useEffect(()=>{
      handleClick(showPrivacyPolicy)
  },[showPrivacyPolicy]) 
  return (
    <>
      {show && (
        <div className={`${classes.modalCookies}`}>
        <div className={`${classes.contenedor}`}>
          <div className={`${classes.messageContainer}`}>
            <p className={`${classes.message} `}>
              {t('textPrincipal')}
              <a className={classes.link} href={t('path')}>
                {t('conditions')}
              </a>
            </p>
          </div>
          <div className={`${classes.actions}`}>
            <Button onClick={()=>handleClick(false)}>
              {t('OK')}
            </Button>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default ModalCookies;
const mapTerminals = terminals => (
    terminals.map(terminal => {
        const { 
            Ciudad, Description, Direccion, IdPAD, ImagenTerminal, Latitud, Longitud, Metadata,
            Nombre, Pais, Subtitulo, Telefono, Text, TitleImagenTerminal, TitlePage, UrlVideo
        } = terminal;

        return {
            city: Ciudad,
            description: Description,
            address: Direccion,
            padId: IdPAD,
            image: ImagenTerminal,
            latitude: Latitud,
            longitude: Longitud,
            metadata: Metadata,
            name: Nombre,
            country: Pais,
            subtitle: Subtitulo,
            phone: Telefono,
            text: Text,
            imageTitle: TitleImagenTerminal,
            pageTitle: TitlePage,
            videoUrl: UrlVideo
        }
    })
)

export default ({ Terminales, Metadata }) => {
    return {
        terminals: mapTerminals(Terminales),
        metadata: Metadata
    }
};

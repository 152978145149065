import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

import Message from 'components/shared/message';
import Icon from 'components/shared/icon';

import useContent from 'hooks/content';
import layoutContext from 'hooks/layout/layoutContext';
import { useApplication } from 'hooks/application';

import mapGenericMessageStrapi from 'hooks/cms/mappers/mapGenericMessageStrapi';
import useRedis from 'hooks/cms/redis';

import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

export default ({ endpoint }) => {
    useStyles(classes);
    const { getGenericMessage } = useContent();
    const { getCache } = useRedis();
    const { idPv } = useApplication();
    const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.sm})` });
    const [data, setData] = useState();
    const [showMessage, setShowMessage] = useState(true);
    const layoutContent = useContext(layoutContext);
    const enableGenericMessage = process.env.FT_ENABLE_STRAPI_BUSES_GENERIC_MESSAGE;
    const enableStrapi = process.env.FT_ENABLE_STRAPI_CMS;

    useEffect(() => {
        if (enableGenericMessage && !enableStrapi) {
            const content = layoutContent?.genericMessage;
            setData(content);
        }
    }, [layoutContent])

    useEffect(() => {
        const getData = async () => {
            const data = await getGenericMessage(endpoint);
            setData(data);
        }
        
        const getContent = async () => {
            const content = await getCache({ key:endpoint,idPv:idPv });
            const cmsContent = mapGenericMessageStrapi(content, 'mensajeGenericoBuses');
            setData(cmsContent);
        }

        if (enableStrapi && enableGenericMessage) {
            getContent();
        } else if (enableGenericMessage) {
            getData();   
        }
    }, []);

    return data && data.text && showMessage
        ? <div className={classes.container} data-testid='message'>
            <Message
                text={data.text}
                messageType={data.type}
                hasHTML={true}
                customClasses={{
                    root: classes.root
                }}
            />
            {
                isMobile &&
                <div className={classes.iconContainer} data-testid='icon' onClick={() => setShowMessage(false)}>
                    <Icon classes={classes.icon} name='times-circle' />
                </div>
            }
        </div>
        : null;
}


import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import ft from '../../../../features.js'
const features = ft.features()

export default ({ image, alt, target, customClasses, title, tracking, customRootClasses, ...rest }) => {
    useStyles(classes);
    const rootClasses = `${classes.root} ${customRootClasses || ''}`;
    

    if(Array.isArray(image))
    {
        return (
            <picture className={rootClasses}>
                {
                    image.map(x => {
                        const fileExtention = x.split('.').pop();

                        return <source key={`image/${fileExtention}`} srcSet={x} type={`image/${fileExtention}`}/>
                    })
                }
                <img 
                    src={image[0]} 
                    alt={alt} 
                    title={title} 
                    target={target} 
                    className={customClasses} 
                    data-tracking={tracking} {...rest}
                />
            </picture>
        )
    }

    const webpPath = image.replace(new RegExp('jpg|png|gif|jpeg'), "webp");
    const splitImage = image.split('.');

    return (
        <picture className={rootClasses}>
            { features.enableWebp && <source srcSet={webpPath} type="image/webp" /> }
            <source srcSet={image} type={`image/${splitImage[splitImage.length - 1]}`} />
            <img 
                src={image} 
                alt={alt} 
                title={title} 
                target={target} 
                className={customClasses} 
                data-tracking={tracking} {...rest}
            />
        </picture>
    )
}
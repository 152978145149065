export default {
    what_is_plataforma_10: 'What is Plataforma 10?',
    service_center: 'Customer Service Center',
    quality_management: 'Quality Management',
    general_conditions: 'General Conditions',
    companies: 'Companies',
    destinations: 'Destinations',
    bus_stations: 'Bus Stations',
    special_trips: 'Special Trips',
    media: 'Media',
    jobs: 'Jobs',
    transportation_companies: 'Transportation Companies',
    contact: 'Contact',
    how_to_buy: 'How to Buy?',
    common_questions: 'Frecuently Asked Questions',
    help: 'Help',
    company: 'Company',
    trips: 'Trips',
    what_is_plataforma_10_url: 'what-is-plataforma-10',
    service_center_url: 'customer-service-center',
    quality_management_url: 'qualitiy-management',
    general_conditions_url: 'general-conditions',
    companies_url: 'joined-transport-companies',
    destinations_url: 'bus-stations/',
    bus_stations_url: 'terminales-micro/',
    special_trips_url: 'special-journeys',
    media_url: 'media',
    jobs_url: 'work-in-plataforma-10',
    transportation_companies_url: 'transport-companies',
    contact_url: 'mailto:sac@plataforma10.com',
    how_to_buy_url: 'how-to-shop',
    help_url: 'help',
    electronicLink:'how-to-show/eletronic',
    countries: 'Countries',
}
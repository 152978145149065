export default {
    howtoShop:'Como comprar?',
    joinOurNet:'Venda conosco',
    blog:'Blog',
    help:'Ajuda',
    howtoShopLink:'como-comprar',
    joinOurNetLink:'informacao-para-centros-de-atencao',
    blogLink:'blog',
    helpLink:'ajuda',
    coverPage:'Frente',
    coverPageLink:'',
    whatIsPlataforma10:'O que é a Plataforma 10?',
    whatIsPlataforma10Link:'o-que-e-plataforma-10',
    electronicLink:'como-comprar/eletronica',
    returnTicket: 'Retornar passagem',
    myPurchasesLink:'usuario/miscompras',
    attention: 'atenção',
    returnLoginMessage: 'Para devolver suas passagens, você deve fazer o login e selecionar as passagens que deseja devolver.'
}
import urls from 'shared/urls';

export default data => {
    const {
        TituloCuentasCorporativas, DescripcionCuentasCorporativas, TituloDetalleCuentasCorporativas,
        DetalleCuentasCorporativas, SubtituloDetalleCuentasCorporativas, TextoBotonAdhesionCuentasCorporativas,
        LinkFormularioCuentasCorporativas, MetadescriptionTituloCuentasCorporativas, MetadescriptionDescriptionCuentasCorporativas,
        TituloAgenciasViajes, DescripcionAgenciasViajes, TituloDetalleAgenciasViajes, SubtituloDetalleAgenciasViajes,
        DetalleAgenciasViajes, TextoBotonAdhesionAgenciasViajes, LinkFormularioAgenciasViajes, MetadescriptionTituloAgenciasViajes,
        MetadescriptionDescriptionAgenciasViajes, TituloOtros, DescripcionOtros, TituloDetalleOtros, SubtituloDetalleOtros,
        DetalleOtros, TextoBotonAdhesionOtros, LinkFormularioOtros, MetadescriptionTituloOtros, MetadescriptionDescriptionOtros,
        Metadata
    } = data;

    return {
        metadata: Metadata,
        types:[
            {
                title: TituloCuentasCorporativas,
                description: DescripcionCuentasCorporativas,
                buttonText: TextoBotonAdhesionCuentasCorporativas,
                detailTitle: TituloDetalleCuentasCorporativas,
                detailSubtitle: SubtituloDetalleCuentasCorporativas,
                detail: DetalleCuentasCorporativas,
                formLink: LinkFormularioCuentasCorporativas,
                metadata: {
                    Title: MetadescriptionTituloCuentasCorporativas,
                    MetaDescription: MetadescriptionDescriptionCuentasCorporativas
                },
                showButton: false,
                image: {
                    url: urls.getPublicPath('images/contenidoinstitucional/foto-adherite-cuenta-corporativa-para-empresas.jpg')
                }
            },
            {
                title: TituloAgenciasViajes,
                description: DescripcionAgenciasViajes,
                buttonText: TextoBotonAdhesionAgenciasViajes,
                detailTitle: TituloDetalleAgenciasViajes,
                detailSubtitle: SubtituloDetalleAgenciasViajes,
                detail: DetalleAgenciasViajes,
                formLink: LinkFormularioAgenciasViajes,
                metadata: {
                    Title: MetadescriptionTituloAgenciasViajes,
                    MetaDescription: MetadescriptionDescriptionAgenciasViajes
                },
                showButton: false,
                image: {
                    url: urls.getPublicPath('images/contenidoinstitucional/foto-adherite-agencias-de-turismo.jpg')
                }
            },
            {
                title: TituloOtros,
                description: DescripcionOtros,
                buttonText: TextoBotonAdhesionOtros,
                detailTitle: TituloDetalleOtros,
                detailSubtitle: SubtituloDetalleOtros,
                detail: DetalleOtros,
                formLink: LinkFormularioOtros,
                metadata: {
                    Title: MetadescriptionTituloOtros,
                    MetaDescription: MetadescriptionDescriptionOtros
                },
                showButton: false,
                image: {
                    url: urls.getPublicPath('images/contenidoinstitucional/foto-adherite-otros.jpg')
                }
            }
        ]
    }
};
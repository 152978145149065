import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomDialog from 'components/shared/customDialog';

import { useError } from 'hooks/error';

import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import 'translations';

export default () => {
    useStyles(classes, globalStyles);
    const { t } = useTranslation();
    const { setError } = useError();

    const [openDialog, setOpenDialog] = useState(true);

    const handleClose = () => {
        setOpenDialog(false);
        setError(false);
    }

    return (
        <>
            { 
                openDialog && 
                    <CustomDialog title={t('errorModalGenericTitle')} onClose={handleClose}>
                        <div className={`${classes.error} ${globalStyles.flexColumn}`}>
                            <span> 
                                { t('errorModalGenericDescription') } &nbsp;
                                { t('reportError') } &nbsp;
                                <a className={classes.link} href={t('mailTo')}>
                                    { t('clickHere') }
                                </a>
                            </span>
                        </div>
                    </CustomDialog>
            }
        </>
    )
}


const mapComments = comments => {
    return comments.map(comment => {
        const { Comentario, Fecha, Usuario, Puntaje} = comment;
        return {
            text: Comentario,
            date: Fecha,
            username: Usuario,
            rating: Puntaje
        }
    })
}

const mapStyles = styles => {
    return {
        searchBoxBgColor: styles.ColorFondoBuscador,
        searchBoxFontColor: styles.ColorLetraBuscador,
        searchBoxButtonBgColor: styles.ColorFondoBotonBuscador,
        searchBoxButtonFontColor: styles.ColorLetraBotonBuscador,
        destinationTitleItemBgColor: styles.ColorFondoTituloDestinosItem,
    }
}

const mapDescription = data => {
    return {
        name: data.Nombre,
        description: data.Descripcion,
        imgOmnibus: data.ImagenOmnibus,
        altImgOmnibus: data.AltImagenOmnibus,
        imgTopRight: data.ImagenOmnibus2,
        altImgTopRight: data.AltImagenOmnibus2,
        imgBottomRight: data.ImagenOmnibus3,
        altImgBottomRight: data.AltImagenOmnibus3,
        imgMap: data.ImagenMapa,
        altImgMap: data.AltImagenMapa
    }
}

const mapMostSearchedTickets = tickets => {
    return tickets.map(t => {
        return {
            image: t.Imagen,
            altImage: t.AltImagen,
            price: t.Precio,
            idOriginPad: t.IdPadOrigen,
            idDestinationPad: t.IdPadDestino,
            originPad: t.PadOrigen,
            alternativeTextOriginPad: t.TextoAlternativoPadOrigen,
            destinationPad: t.PadDestino,
            alternativeTextdestinationPad: t.TextoAlternativoPadDestino,
            isBanner: t.EsBanner,
            bannerLink: t.BannerLink,
            date: t.FechaIda,
            daysAfterDepartureDate: t.CantidadDiasPosteriorFechaIda,
        }
    })
}


const mapRoutes = routes => {
    return routes.map(r => {
        const { IdPadOrigen, IdPadDestino, PadOrigen, PadDestino, Precio } = r;
        return {
            idOriginPad: IdPadOrigen,
            idDestinationPad: IdPadDestino,
            originPad: PadOrigen,
            destinationPad: PadDestino,
            price: Precio
        }
    })
}

const mapLanding = landing => {
    return {
        idTransportationCompany: landing.IdEmpresaTransporte,
        idEconomicGroup: landing.IdGrupoEconomico,
        logo: landing.Logo,
        metadata: landing.Metadata,
        description: mapDescription(landing.Descripcion),
        mostSearchedTickets: mapMostSearchedTickets(landing.PasajesMasBuscados),
        routes: mapRoutes(landing.Rutas),
        title: landing.Titulo,
        country: landing.Pais,
        redirectionUrl: landing.UrlRedireccion,
        showRating: landing.MostrarRating,
        active: true
    }
}

export default ({ Landing, UrlRedireccion, Comentarios }) => ({
    landing: !UrlRedireccion
        ? mapLanding(Landing)
        : { active: false, redirectionUrl: UrlRedireccion },
    styles: !UrlRedireccion
        ? mapStyles(Landing.Estilos)
        : {},
    comments: mapComments(Comentarios || [])
});
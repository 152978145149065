import getFirst from './getFirst';

const mapGenericMessage = (genericMessage, collectioName) => {
    const content = getFirst(genericMessage, collectioName);
    
    return content ? getGenericMessageContent(content) : null;
}

const getGenericMessageContent = response => {
    const { texto, tipo } = response?.mensajeGenerico;
 
    return  texto && response.activo
    ? {
        text: texto,
        type: tipo,
        activo: true
    }
    : null
 }
 
 export default mapGenericMessage
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import moment from 'moment';
import './translations';

export default () => {
  useStyles(classes);
  const { t } = useTranslation('owFooter');
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(1000);
    setReady(true);
  }, []);

  return (
    <footer>
      <div className={classes.container}>
        {ready ? (
          <div className={classes.linksWrapper}>
            <Link to={t('generalConditionsLink')} className={classes.link}>
              {t('generalConditions')}
            </Link>
            &nbsp; | &nbsp;
            <Link to={t('frequentQuestionsLink')} className={classes.link}>
              {t('frequentQuestions')}
            </Link>
          </div>) : (
          <Skeleton
            style={{ width: 350, height: 20 }}
            rows={1}
            active />
        )
        }
        {ready ? (
          <div className={classes.copyright}>
            <span>
              {`© 2001-${moment().year()} Plataforma 10 | ${t(
                'rightsReserved'
              )}`}
            </span>
          </div>) : (
          <Skeleton
            style={{ width: 350, boxSizing: 'border-box' }}
            rows={1}
            active />
        )}
      </div>
    </footer>
  );
};

import axios from 'axios';
import https from 'https';

// Hooks
import { useApplication } from 'hooks/application';
import { useError } from 'hooks/error';

export default () => {
    const { host } = useApplication();
    const { setError } = useError();

    const opts = {
        httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        baseURL: `https://${host}`
    }

    const interceptorError = error => {
        setError(true);
        Promise.reject(error.response);
    }

    const instanse = axios.create(opts);
    
    instanse.interceptors.response.use(response => response, interceptorError);

    return instanse;
}
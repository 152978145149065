export default deal => ({
    destinationId: deal.IdDestino,
    circuitId: deal.IdCircuito,
    title: deal.Titulo,
    price: deal.Precio,
    showPrice: true,
    image: {
        url: deal.Imagen,
        alt: deal.Titulo
    }
})
const mapMetaData = data =>{
    const { title,description,urlImagen } = data;
    let { urlCanonica } = data;

    if(!urlCanonica){
        urlCanonica=window.location.href;
    }

    return{
        Title:title,
        MetaDescription:description,
        UrlCanonica:urlCanonica,
        UrlImagen:urlImagen?.url
    }
}

export default mapMetaData;
import React from 'react';
import { Link } from 'react-router-dom';

import { trackClick } from 'hooks/tracking';
import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import getLinks from './footerLinkManager';
import { useTranslation } from 'react-i18next';

export default () => {
	useStyles(classes, globalStyles);
    const { country, host } = useApplication();
    const { t } = useTranslation('links');
    const getHostByCountry = (domain) => {
        var baseHost = host.substr(0, host.indexOf('.com.'));
        return 'https://' + baseHost + '.com.' + domain;
    }
    const links = getLinks(country.url);

    const showBook =(e)=>{
        e.preventDefault()
        window.wcbox.show()
    }

    const getLinkComponent = (item)=>{
        if(country.url === 'pe')
        {
            if (item.showComplaintsBook){
                return (<a 
                    href={item.url} 
                    className={classes.link} 
                    onClick={showBook}
                    >
                        { item.text }
                    </a>)
            }
        }

        if(item.to ){
            return(
                    <Link 
                    to={item.url} 
                    className={classes.link} 
                    onClick={() => trackClick(`Link footer ${item.text}`)}
                    >
                        { item.text }
                    </Link>                
                    )
        }else{
            return(

                    <a 
                    href={item.url} 
                    className={classes.link} 
                    onClick={() => trackClick(`Link footer ${item.text}`)}
                    >
                        { item.text }
                    </a>            
                    )
        }

    }
    return (
        <div className={classes.links}>
            {
                links && links.map((list, index) => 
                    <div key={index}>
                        <span className={classes.title}>
                            { list.title }
                        </span>
                        <ul className={classes.ul}>
                            {
                                list.items.map((item, index) => 
                                    item &&
                                        <li className={globalStyles.p5} key={index}>
                                        
                                        {
                                            getLinkComponent(item)
                                        }
                                        </li>
                                )
                            }
                        </ul>
                    </div>
                )
            }
            {
                <div>
                    <span className={classes.title}>
                        {t('countries')}
                    </span>
                    <ul className={classes.ul}>
                            <li className={globalStyles.p5}><a className={classes.link} href={`${getHostByCountry('ar')}`}>Argentina</a></li>
                            <li className={globalStyles.p5}><a className={classes.link} href={`${getHostByCountry('py')}`}>Paraguay</a></li>
                            <li className={globalStyles.p5}><a className={classes.link} href={`${getHostByCountry('br')}`}>Brasil</a></li>
                            <li className={globalStyles.p5}><a className={classes.link} href={`${getHostByCountry('pe')}`}>Peru</a></li>
                    </ul>

                </div>
            }
        </div>
    )
}
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import es from './es';
import pt from './pt';
import en from './en';
import pe from './pe';

const defaultLang = "es-AR";

i18next
  .use(initReactI18next)
  .init({
    resources: {
      "es-AR": {
        translation: es
      },
      "en-US": {
        translation: en
      },
      "es-PE": {
        translation: pe
      },
      "pt-BR": {
        translation: pt
      },
    },
    lng: defaultLang,
    fallbackLng: defaultLang,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: false,
      bindI18n: false,
      bindStore: false
    }
  });

export default i18next;
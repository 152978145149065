const Types = Object.freeze({
    Ow: { name: 'ow', type:1},
    Pcom: { name: 'pcom', type: 2 },
    Landing: { name: 'landing', type:3}
});

const get = type => {
    let applicationType; 
    for (let name in Types) {
        if (Types[name].type === type) {
            applicationType = Types[name];
            break;
        }
    }

    return applicationType;
}
export {Types};

export default type => { 
    return get(type);
}
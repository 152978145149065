export default {
    howtoShop:'¿Cómo comprar?',
    joinOurNet:'Súmate a la red',
    blog:'Blog',
    help:'Ayuda',
    howtoShopLink:'como-comprar',
    joinOurNetLink:'viajes/informacion-sobre-centros-de-atencion/',
    blogLink:'blog',
    helpLink:'ayuda',
    coverPage:'Portada',
    coverPageLink:'',
    whatIsPlataforma10:'¿Qué es plataforma 10?',
    whatIsPlataforma10Link:'que-es-plataforma-10',
    electronicLink:'como-comprar/electronica',
    returnTicket: 'Devolver pasaje',
    myPurchasesLink:'usuario/miscompras',
    attention: 'atención',
    returnLoginMessage: 'Para devolver tus pasajes tenés que loguearte y luego seleccionar los pasajes a devolver.'
}
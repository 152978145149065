export default ({ contenidoSeo }) => {
    if(!contenidoSeo) return null;
    
    const {
        mobile,
        desktop,
        titulo,
        texto
    } = contenidoSeo

    return { 
        mobile: mobile,
        desktop: desktop,
        title: titulo,
        content: texto
    }
}
import isServer from 'shared/isServer';

const trackClick = action => {
    try {

        const eventData = {
            event: 'trackEvent',
            eventCategory: 'Tracking Click',
            eventAction: action
        }

        if(!isServer()) {
            window.dataLayer.push(eventData);
        }

    } catch (e) {}
}

export { trackClick }
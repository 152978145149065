export default (t) => {
    return [
        {
            title: t('company'),
            items: [
                { to:false, text: t('what_is_plataforma_10'), url: `/${t('what_is_plataforma_10_url')}` },
                { to:false, text: t('service_center'), url: `/${t('service_center_url')}` },
                { to:false, text: t('general_conditions'), url: `/${t('general_conditions_url')}` }
            ]
        },
        {
            title: t('trips'),
            items: [
                { to:false, text: t('companies'), url: `/${t('companies_url')}` },
                { text: t('destinations'), url: `/${t('destinations_url')}` },
                { to:true, text: t('bus_stations'), url: `/${t('bus_stations_url')}` },
                { to:true, text: t('special_trips'), url: `/${t('special_trips_url')}` } 
            ]
        },
        {
            title: t('contact'),
            items: [
                { to:false, text: t('media'), url: `/${t('media_url')}` },
                { to:true, text: t('jobs'), url: `/${t('jobs_url')}` },
                { to:false, text: t('transportation_companies'), url: `/${t('transportation_companies_url')}` },
                { text: t('contact'), url: t('contact_url') }
            ]
        },
        {
            title: t('help'),
            items: [
                { to:true, text: t('how_to_buy'), url: `/${t('how_to_buy_url')}` },
                { to:false, text: t('common_questions'), url: `/${t('help_url')}` }
            ]
        }
]
}
export default {
    howtoShop:'¿Cómo comprar?',
    joinOurNet:'Súmate a la red',
    blog:'Blog',
    help:'Ayuda',
    howtoShopLink:'como-comprar',
    joinOurNetLink:'informacion-para-centros-de-atencion',
    blogLink:'viajes/blog/',
    helpLink:'ayuda',
    coverPage:'Portada',
    coverPageLink:'',
    whatIsPlataforma10:'¿Qué es plataforma 10?',
    whatIsPlataforma10Link:'que-es-plataforma-10',
    electronicLink:'viajes/como-comprar/',
    returnTicket: 'Devolver pasaje',
    myPurchasesLink:'usuario/miscompras',
    attention: 'atención',
    returnLoginMessage: 'Para devolver tus pasajes tenés que loguearte y luego seleccionar los pasajes a devolver.',
    common_questions: 'Libro de reclamaciones'
}
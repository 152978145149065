export default data => {
    const { Carrusel } = data;

    return Carrusel.slides
        .filter(x => x.activo)
        .map(item => ({
            link: item.link,
            image: {
                id: item.id,
                image: item.imagen.map(x => x.url),
                alt: item.imagen[0].alternativeText,
                title: item.imagen[0].caption,
            }
        }))
}
export default {
    what_is_plataforma_10: 'O que é a Plataforma 10?',
    service_center: 'Centros de Atendimento',
    quality_management: 'Gestão de Qualidade',
    general_conditions: 'Condições Gerais',
    companies: 'Viações',
    destinations: 'Destinos',
    bus_stations: 'Rodoviárias',
    special_trips: 'Viagens Especiais',
    media: 'Fale Conosco',
    jobs: 'Oportunidades',
    transportation_companies: 'Viações',
    contact: 'Contato',
    how_to_buy: 'Como Comprar?',
    common_questions: 'Perguntas Frequentes',
    help: 'Ajuda',
    company: 'Empresa',
    trips: 'Viagens',
    what_is_plataforma_10_url: 'o-que-e-plataforma-10',
    service_center_url: 'informacao-para-centros-de-atencao',
    quality_management_url: 'gestao-de-qualidade',
    general_conditions_url: 'condicoes-gerais',
    companies_url: 'empresas-de-transporte',
    destinations_url: 'destinos/',
    bus_stations_url: 'rodoviarias-onibus/',
    special_trips_url: 'viagens-especiais',
    media_url: 'meios',
    jobs_url: 'oportunidades-na-plataforma-10',
    transportation_companies_url: 'informacao-para-empresas-de-transporte',
    contact_url: 'mailto:atendimento@plataforma10.com',
    how_to_buy_url: 'como-comprar',
    help_url: 'viagens/ajuda/',
    electronicLink:'como-comprar/eletronica',
    countries: 'Países',
}
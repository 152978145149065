import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useApplication } from "hooks/application";

import FollowUs from 'components/footer/followUs';
import FollowUsStrapi from 'components/footer/followUsNew';
import Certificates from 'components/footer/certificates';
import CertificatesStrapi from 'components/footer/certificatesNew';
import Links from 'components/footer/links';
import ModalCookies from '../shared/modalCookies';
import cookie from '../../shared/cookie';

import LayoutContext from 'hooks/layout/layoutContext';

import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import moment from 'moment';
import './translations';

export default() => {
	useStyles(classes, globalStyles);
	const { t } = useTranslation('footer');
	const { country } = useApplication();
	const [year, setYear] = useState();
	const isDesktop = useMediaQuery({ query: `(min-width:${breakpoints.sm})`});
	const [data, setData] = useState(null);
	const layoutContent = useContext(LayoutContext);
	const [showPrivacyPolicy, SetShowPrivacyPolicy] = useState(false);
	const enableFooter = process.env.FT_ENABLE_STRAPI_FOOTER;
	const enableStrapi = process.env.FT_ENABLE_STRAPI_CMS;
	const isPeru = (country.url==='pe');
	useEffect(() => {
		setYear(moment().year());

	    if(enableStrapi && enableFooter){
			const content = layoutContent?.footer;
			setData(content);
		}
		const privacyPolicy = cookie.get("privacyPolicy") 
		if(country.url === "br" && !privacyPolicy) {
		   SetShowPrivacyPolicy(true)
		 }else{
		   SetShowPrivacyPolicy(false)
		 }

	}, [layoutContent]);

	useEffect(() => {
		
		}, []);

	return (
		<>
		<footer>
			<section>
				<div className={`${classes.root} ${globalStyles.flex}`}>
					<div className={`${classes.container} ${globalStyles.flex}`}>
						<div>
							{data ? (<FollowUsStrapi data={data.socialMedia} />)
								: (<FollowUs />)}

						</div>
						{isDesktop && <Links />}
					</div>
				</div>
			</section>
			{
				isDesktop &&
				<>
					<section>
						{data ? (<CertificatesStrapi data={data.certificates} />) :
							(<Certificates />)}
					</section>
					<section>
						{
							country && country.url === 'br' ?
								<div className={`${classes.companyDataStyle}`}>
									<p>Serviços de Distribuição de Tecnologia de Informática e Comunicações 
										do Brasil LTDA., CNPJ/MF  nº 16.681.856/0001-00, Alameda Araguaia, 
										nº 933, conj. 81, Alphaville Centro Empresarial, Barueri/SP, 
										CEP: 06455-000 - Agência de Turismo Cadastro 16.681.856/0001-00</p>
								</div>
							: <></>
						}
						
						<br/>
						<div className={classes.container}>
							<span className={classes.copyright}>
							{!isPeru? data ? (data.text) :
									(year &&
										<span>
											&copy; 2001-{year} Plataforma 10. {t('allRightsReserved')}.
										</span>)
								:
								<span>
									&copy; {year} SEDITICS PERU SAC. Todos los derechos reservados.
								</span>
							}
							</span>
						</div>
					</section>
				</>
			}
			<ModalCookies showPrivacyPolicy={showPrivacyPolicy} />
		</footer>
		</>
	);
}

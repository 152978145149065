export default {
    what_is_plataforma_10: '¿Qué es Plataforma 10?',
    service_center: 'Centros de Atención',
    quality_management: 'Gestión de Calidad',
    general_conditions: 'Condiciones Generales',
    companies: 'Empresas',
    destinations: 'Destinos',
    bus_stations: 'Terminales de Micro',
    special_trips: 'Viajes Especiales',
    media: 'Medios',
    jobs: 'Empleos?',
    transportation_companies: 'Empresas de Transporte',
    contact: 'Contacto',
    be_part: 'Sé parte',
    how_to_buy: '¿Cómo comprar?',
    common_questions: 'Preguntas Frecuentes',
    complaints_book: 'Libro de reclamaciones',
    company: 'Empresa',
    trips: 'Viajes',
    what_is_plataforma_10_url: 'viajes/que-es-plataforma-10/',
    service_center_url: 'informacion-para-centros-de-atencion',
    quality_management_url: 'gestion-de-calidad',
    general_conditions_url: 'viajes/condiciones-generales/',
    companies_url: 'viajes/empresas-de-bus/',
    companies_url_py: 'empresas-de-omnibus/',
    destinations_url: 'viajes/destinos/',
    bus_stations_url: 'viajes/terminales-terrestres/',
    special_trips_url: 'viajes-especiales',
    media_url: 'medios',
    jobs_url: 'empleos',
    transportation_companies_url: 'viajes/informacion-para-empresas-de-transporte/',
    contact_url: 'mailto:sac@plataforma10.com',
    how_to_buy_url: 'como-comprar',
    help_url: 'viajes/ayuda/',
    complaints_book_url: '#',
    electronicLink:'como-comprar/electronica',
    countries: 'Países',
}
export default {
    urlBuses: '',
    urlServices: 'servicos',
    urlVuelos: 'https://plataforma10.kiwi.com/pt/',
    urlFluviales: 'passagens-navios',
    urlRemises: 'traslados-remises',
    urlTransfers: 'transfer',
    urlCyT: 'transfer-e-passeios',
    urlHoteles: 'hoteis',
    urlViajesEspeciales: 'viagens-especiais',
    Omnibus: 'Ônibus',
    Omnibus_tren_patagonico: 'Tren Patagonico',
    Vuelos: 'Voos',
    Fluviales: 'Navios',
    Remises: 'Remises',
    Transfers: 'Transfers',
    CyT: 'Passeios e EXCURSÔES',
    Hoteles: 'Hoteis',
    ViajesEspeciales: 'Viagens especiais',
    new: 'Novo'
}
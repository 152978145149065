const mapLogos = logos => {
    return logos.map(logo => {
        const { ImgAlt, UrlImagen } = logo;
    
        return {
            url: UrlImagen,
            alt: ImgAlt
        }
    })
}

export default ({ Descripcion, Logos, Metadata, Subtitulo, Titulo, EmailContacto }) => ({
    description: Descripcion,
    metadata: Metadata,
    logos: mapLogos(Logos),
    title: Titulo,
    subtitle: Subtitulo,
    email: EmailContacto
});
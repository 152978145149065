import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import globalStyles from 'styles/main.scss';

export default ({ data }) => {
  useStyles(globalStyles);
  
  return (
    <div className={globalStyles.flexJustifyCenter}>
      {
        data.map((cert, index) => {
            const {link, image, alt} = cert;

            return (
                <div key={index} className={globalStyles.mlr10}>
                  <a href={link}>
                      <img src={image} alt={alt} />
                  </a>
                </div>
            );
        })
      }
    </div>
  )
}
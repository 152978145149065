const mapLandings = landings => {
    return landings.map(company => {
        const id = company.Url.split('/').pop();
        
        return {
            url: company.Url,
            name: company.Nombre,
            logo: company.Logo,
            country: company.Pais.toLowerCase(),
            newCMS:false,
            id
        }
    })
}

const mapCompanies = companiesByLetter => {
    return companiesByLetter.map(companies => {
        return {
            landings: mapLandings(companies.Landings),
            letter: companies.Letra,
        }
    })
}

export default ({ LandingsPorLetra, Metadata }) => {
    return {
        metadata: Metadata,
        companies: mapCompanies(LandingsPorLetra)
    }
}
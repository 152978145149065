// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6d4830eaf04fd04267a37d0255144569-scss{width:80%;margin:0 auto}._03b398bd0ba656c1cf8b5417797f95e2-scss{color:var(--primary-main)}._03b398bd0ba656c1cf8b5417797f95e2-scss:hover{color:var(--black-dark)}\n", ""]);
// Exports
exports.locals = {
	"error": "_6d4830eaf04fd04267a37d0255144569-scss",
	"link": "_03b398bd0ba656c1cf8b5417797f95e2-scss"
};
module.exports = exports;

import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonsType } from 'shared/buttonsType';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';

const Button = ({ children, onClick, to, buttonsType, customClasses, ...rest }) => {
    useStyles(classes);
    const style = customClasses ? `${customClasses} ${classes.buttonFilled}` : classes.buttonFilled;

    const handleClick = () => {
        if (onClick) onClick()
    }

    const attributes = {
        className: style,
        onClick: handleClick,
        ...rest
    };

    const type = {
        button: <button {...attributes} >{children}</button>,
        externalLink: <a target='_blank' href={to} {...attributes}>{children}</a>,
        link: <Link to={to} {...attributes}>{children}</Link>,
    }

    const buttonType = buttonsType ? buttonsType : ButtonsType.Button;
   
    const Component = () => type[buttonType];

    return (<Component />)
}

Button.defaultProps = {
    to: '',
}

export default Button;
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".b6abc6b636db0e4ddd1e46073102ef27-scss{display:grid;grid-template-columns:repeat(5, 1fr)}.b6abc6b636db0e4ddd1e46073102ef27-scss ._48327402480a8fb6521ca6fa70289e77-scss{color:var(--white-light);font-weight:900;font-size:1rem}.b6abc6b636db0e4ddd1e46073102ef27-scss .c14f8473fc189b14d662cd7e31d89498-scss{color:var(--grey-light);transition:color 0.2s ease-in-out;font-size:13px;text-decoration:none}.b6abc6b636db0e4ddd1e46073102ef27-scss .c14f8473fc189b14d662cd7e31d89498-scss:hover{color:var(--primary-main)}\n", ""]);
// Exports
exports.locals = {
	"links": "b6abc6b636db0e4ddd1e46073102ef27-scss",
	"title": "_48327402480a8fb6521ca6fa70289e77-scss",
	"link": "c14f8473fc189b14d662cd7e31d89498-scss"
};
module.exports = exports;

import React from 'react';

import Icon from 'components/shared/icon';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default ({ text, icon, customClasses, messageType = 'error', hasHTML = false }) => {
    useStyles(classes, globalStyles);
    const types = {
        warning: {
            classes: {
                container: `${classes.container} ${classes.warningContainer}`,
            },
            icon: 'exclamation-triangle-solid'
        },
        information:{
            classes: {
                container: `${classes.container} ${classes.informationContainer}`,
            },
            icon: 'info-circle-solid'
        },
        error: {
            classes: { container: classes.container }
        }
    }
    
    return (
        <div 
            className={
                customClasses 
                    ? `${types[messageType].classes.container} ${globalStyles.flexCenter} ${customClasses.root}` 
                    : `${types[messageType].classes.container} ${globalStyles.flexCenter}`
            }
        >
            {
                types[messageType].icon && 
                    <div>
                        <Icon 
                            name={!icon && types[messageType].icon}
                            classes={customClasses ? `${classes.icon} ${customClasses.messageIcon}` : classes.icon} 
                        />
                    </div> 
            }
            <div className={customClasses ? `${classes.textContainer} ${customClasses.messageText}` : classes.textContainer}>
                {
                    hasHTML 
                        ? <p dangerouslySetInnerHTML={{ __html: text}} />
                        : <span> { text } </span>
                }
            </div>
        </div>
    )
}


export default (t) => {
	return [
        {
            title: t('company'),
            items: [
                { showComplaintsBook:false, to:false, text: t('what_is_plataforma_10'), url: `/${t('what_is_plataforma_10_url')}` },
                { showComplaintsBook:false, to:false, text: t('general_conditions'), url: `/${t('general_conditions_url')}` }
            ]
        },
        {
            title: t('trips'),
            items: [
                { showComplaintsBook:false, to:false, text: t('companies'), url: `/${t('companies_url')}` },
                { showComplaintsBook:false, to:false, text: t('destinations'), url: `/${t('destinations_url')}` },
                { showComplaintsBook:false, to:false, text: t('bus_stations'), url: `/${t('bus_stations_url')}` }
            ]
        },
        {
            title: t('be_part'),
            items: [
                { showComplaintsBook:false, to:false, text: t('transportation_companies'), url: `/${t('transportation_companies_url')}` },
                { showComplaintsBook:false, text: t('contact'), url: '#' }
            ]
        },
        {
            title: t('contact'),
            items: [
                { showComplaintsBook:false, to:false, text: t('common_questions'), url: `/${t('help_url')}` },
                { showComplaintsBook:true, to:true, text: t('complaints_book'), url: `#` }
            ]
        }
]
}
import React from 'react';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default ({ children }) => {
    useStyles(classes, globalStyles);
    
    return (
        <nav>
            <div className={`${classes.items} ${classes.navbar} ${globalStyles.flexColumn}`}>
                <ul> { children } </ul>
            </div>
        </nav>
    )
}

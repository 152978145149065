import moment from 'moment';

class JsonLd {
    generateRating = data => {
        const { landing, comments } = data;
       
        if(!landing || !landing.showRating || !comments ||comments.length === 0) return null;
        
        const reviews = [];
        const ratings = comments.map(x => x.rating);
        const logo = (Array.isArray(landing.logo)? landing.logo.map(item => item.replace('//s3.amazonaws.com', 'https://s3.amazonaws.com')) : landing.logo.replace('//s3.amazonaws.com', 'https://s3.amazonaws.com'));
        const structuratedData = {
            aggregateRating: {
                reviewCount: comments.length > 0 
                    ? comments.map((x) => x.rating).filter(x => x > 2).reduce((a,b) => a+b) 
                    : 0,
                ratingValue: ratings.length > 0 
                    ? Math.round(ratings.reduce((a, b) => a+b, 0) / ratings.length) 
                    : 0,
                '@type': 'AggregateRating',
            },
            review:[],
            priceRange: null,
            name: landing.description.name,
            address: {
                addressLocality: '',
                addressRegion: null,
                streetAddress: '',
                postalCode: null,
                addressCountry: landing.country
            },
            image: logo,
            telephone: '5252-9873',
            '@context': 'http://schema.org/',
            '@type': 'LocalBusiness',
		};
		
		comments.map(comment => {
			if(comment.rating > 2) {
				reviews.push({
                    reviewRating: {
                        ratingValue: comment.rating
                    },
                    datePublished: moment(comment.date).format('DD/MM/YYYY'),
                    description: comment.text,
                    author:{"@type": "Person",name:comment.username}
				})
			}
		})
		structuratedData.review = reviews;
        
        return JSON.stringify(structuratedData);
    }
}

export default new JsonLd();

const mapImage = image => {
    const { ImgAlt, UrlImagen } = image;

    return {
        url: UrlImagen,
        alt: ImgAlt
    }
}

const mapDefaultCity = defaultCity => {
    if(defaultCity) {
        const { Id, Nombre } = defaultCity.Data;
        
        return {
            id: Id,
            name: Nombre
        }
    };

    return {};
}

export default data => {
    const {
        Descripcion, TituloCompraPresencial, ImagenCompraPresencial, DescripcionCompraPresencial,
        TextoBotonCompraPresencial, TextoBotonCompraElectronica, TextoBotonCompraMobile,
        TituloCompraElectronica, ImagenCompraElectronica, DescripcionCompraElectronica,
        TituloCompraMobile, ImagenCompraMobile, DescripcionCompraMobile, Metadata
    } = data.comoComprar;

    return {
        description: Descripcion,
        metadata:Metadata,
        defaultCity: mapDefaultCity(data.provinciaDefault),
        ways:[
            {
                title: TituloCompraPresencial,
                image: mapImage(ImagenCompraPresencial),
                description: DescripcionCompraPresencial,
                buttonText: TextoBotonCompraPresencial,
                showButton: data.provinciaDefault ? true : false,
            },
            {
                title: TituloCompraElectronica,
                image: mapImage(ImagenCompraElectronica),
                description: DescripcionCompraElectronica,
                buttonText: TextoBotonCompraElectronica,
                showButton: true,
            },
            {
                title: TituloCompraMobile,
                image: mapImage(ImagenCompraMobile),
                description: DescripcionCompraMobile,
                buttonText: TextoBotonCompraMobile,
                showButton: true,
            }
        ]
    }
};
import React, { useState }  from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LeftContainer from 'components/header/leftContainer';
import Navbar from 'components/header/navbar';
import Country from 'components/header/leftContainer/country';
import MyAccount from 'components/header/myAccount';
import { trackClick } from 'hooks/tracking';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from '../style.module.scss';
import globalStyles from 'styles/main.scss';
import '../translations';
import Currency from 'components/header/leftContainer/currency';

import CustomDialog from 'components/shared/customDialog';

const HeaderPe = ( {parameters} ) => {
    const { isMobile, lng} = parameters;
    useStyles(classes, globalStyles);
	const { t } = useTranslation('header');
    const [showDialog, setShowDialog] = useState(false);
    const onDialogClose = () => window.location = `/${t('myPurchasesLink',{lng:lng})}`;

    const redirectToGoogleForm = () => {
        trackClick("Link Sumate A La Red Header");
        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSfzkNE7G5b3oouLcgBa66b_WmuIYZr7hqJks6foD_HqtpASzQ/viewform";
    };

    const showBook =(e)=>{
        e.preventDefault()
        window.wcbox.show()
    }

    return (
        <div className={classes.root}>
            <LeftContainer />
            <div className={classes.rightContainer}>
                <div className={classes.topContainer}>             										
                    {isMobile && <Country />}
                    {isMobile && <Currency />  }
                    <MyAccount />					
                </div>
                <div>
                    <Navbar>
                        <li>
                            <a
                                className={classes.link}
                                href={`/${t('electronicLink',{lng:lng})}`}
                                onClick={() => trackClick("Link Como Comprar Header")}>
                                {t('howtoShop',{lng:lng})}
                            </a>
                        </li>
                        <li>
                            <Link
                                className={classes.link}
                                onClick={redirectToGoogleForm}
                            >
                                {t('joinOurNet',{lng:lng})}
                            </Link>
                        </li>
                        <li>
                            <a
                                className={classes.link}
                                href={`/${t('blogLink',{lng:lng})}`}
                                onClick={() => trackClick("Link Blog Header")}>
                                {t('blog',{lng:lng})}
                            </a>
                        </li>
                        {
                            isMobile &&
                            <>
                                <li>
                                    <Link
                                        className={classes.link}
                                        to={`/${t('helpLink',{lng:lng})}`}
                                        onClick={() => trackClick("Link Ayuda Header")}
                                    >
                                        {t('help',{lng:lng})}
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        className={classes.link}
                                        href='#'
                                        onClick={showBook}
                                    >
                                        {t('common_questions',{lng:lng})}
                                    </a>
                                </li>
                            </>
                        }
                    </Navbar>
                </div>
            </div>
            {
				showDialog &&
				<CustomDialog onClose={onDialogClose} title={t('attention',{lng:lng})}>
					<div className={globalStyles.flexColumn}>
						<span> {t('returnLoginMessage',{lng:lng})} </span>
					</div>
				</CustomDialog>
			}
        </div>
    )
} 

export default HeaderPe;
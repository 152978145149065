import i18next  from 'i18next';

import es from './es';
import pt from './pt';
import en from './en';
import pe from './pe';

i18next.addResourceBundle('es', 'product', es);
i18next.addResourceBundle('es-PE', 'product', pe);
i18next.addResourceBundle('en', 'product', en);
i18next.addResourceBundle('pt', 'product', pt);
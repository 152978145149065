import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LeftContainer from 'components/header/leftContainer';
import Navbar from 'components/header/navbar';
import Country from 'components/header/leftContainer/country';
import MyAccount from 'components/header/myAccount';
import { trackClick } from 'hooks/tracking';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from '../style.module.scss';
import globalStyles from 'styles/main.scss';
import '../translations';
import Currency from 'components/header/leftContainer/currency';

const HeaderBr = ( {parameters} ) => {
    
    const { isMobile, lng} = parameters;
    useStyles(classes, globalStyles);
	const { t } = useTranslation('header');

    return (
        <div className={classes.root}>
        <LeftContainer />
        <div className={classes.rightContainer}>
            <div className={classes.topContainer}>										
                {isMobile && <Country />}
                {isMobile && <Currency />  }
                <MyAccount />		
            </div>
            <div>
                <Navbar>
                    <li>
                        <Link
                            className={classes.link}
                            to={`/${t('electronicLink',{lng:lng})}`}
                            onClick={() => trackClick("Link Como Comprar Header")}>
                            {t('howtoShop',{lng:lng})}
                        </Link>
                    </li>
                    <li>
                        <Link
                            className={classes.link}
                            to={`/${t('joinOurNetLink',{lng:lng})}`}
                            onClick={() => trackClick("Link Sumate A La Red Header")}
                        >
                            {t('joinOurNet',{lng:lng})}
                        </Link>
                    </li>
                    <li>
                        <a
                            className={classes.link}
                            href={`/${t('blogLink',{lng:lng})}`}
                            onClick={() => trackClick("Link Blog Header")}>
                            {t('blog',{lng:lng})}
                        </a>
                    </li>
                    {
                        isMobile &&
                        <li>
                            <Link
                                className={classes.link}
                                to={`/${t('helpLink',{lng:lng})}`}
                                onClick={() => trackClick("Link Ayuda Header")}
                            >
                                {t('help',{lng:lng})}
                            </Link>
                        </li>
                    }
                </Navbar>
            </div>
        </div>
    </div>
    )
} 

export default HeaderBr;
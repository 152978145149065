import React, { useState, useEffect, useCallback } from 'react';

import Dropdown from 'components/header/leftContainer/dropdown';

import { useApplication } from 'hooks/application';

import cookie from 'shared/cookie';

export default () => {
    const [sortedCountries, setSortedCountries] = useState([]);
    const { countries, country: activeCountry } = useApplication();

    useEffect(() => {
        const sortedCountries = sortCountries();
        setSortedCountries(sortedCountries);
    }, []);

    const sortCountries =  useCallback(() => {
        const filteredCountries = countries.filter(country => country.url !== activeCountry.url)
        filteredCountries.unshift(activeCountry);

        return filteredCountries;
    }, [activeCountry.url]);

    const changeCountry = country => {
        const location = window.location;
        const host = location.host.split(".");
        const urlCountry = host[host.length - 1].toLowerCase();

        if (urlCountry !== country.url.toLowerCase()) {
            host[host.length - 1] = country.url.toLowerCase();
            const url = host.join('.');
            cookie.set('COUNTRY_CODE', activeCountry.url, 365);
            window.location = `${location.protocol}//${url}`;
        }
    }

    return (
        sortedCountries.length > 0
            ? <Dropdown 
                data={sortedCountries} 
                dropdownTitle={activeCountry.name} 
                onClick={changeCountry} 
                property='name' 
              />
            : null
    )
}
import LinksByAR from './footerLinkManager_AR';
import LinksByPY from './footerLinkManager_PY';
import LinksByPE from './footerLinkManager_PE';
import LinksByBR from './footerLinkManager_BR';
import './translations';
import { useTranslation } from 'react-i18next';

export default (country) => {
    const { t } = useTranslation('links');

    let links;
	switch (country) {
        case 'ar':
            links = LinksByAR(t);
            break;
        case 'br':
            links = LinksByBR(t);
            break;
        case 'py':
            links = LinksByPY(t);
            break;
        case 'pe':
            links = LinksByPE(t);
            break;
        default:
            links = LinksByAR(t);
            break;
    }
	return links;
}
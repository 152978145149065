import React, { useEffect, useState } from 'react';

import Dropdown from 'components/header/leftContainer/dropdown';

import { useApplication } from 'hooks/application';
import { trackClick } from 'hooks/tracking';

import cookie from 'shared/cookie';
import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import { useMediaQuery } from 'react-responsive';
import breakpoints from 'shared/breakpoints';

export default () => {
    const { currencies, activeCurrency, setApplication, features: { enableRealCurrencySymbol },typeApplication } = useApplication();
    useStyles(classes,globalStyles);
    const [sortedCcurrencies, setSortedCcurrencies] = useState([]);

    const isDesktop = useMediaQuery({ query: `(min-width:${breakpoints.md})`});

    const KEY_MONEDA = 'moneda';

    const changeCurrency = currency => setApplication({ type: 'activeCurrency', value: currency });

    const getCookie = cookieMoneda => {
        const currencyCookie = cookieMoneda ? JSON.parse(cookieMoneda) : null;
        if(currencyCookie && enableRealCurrencySymbol && currencyCookie.codigoAMostrar)
            return cookieMoneda;
        else
            return null 
    }

    useEffect(() => {
        const currencyCookie = getCookie(cookie.get(KEY_MONEDA));

        if (currencyCookie) {
            const currency = JSON.parse(currencyCookie);
            if (enableRealCurrencySymbol) {
                changeCurrency({ codeShow: currency.codigoAMostrar, code: currency.codigo, value: currency.cotizacion}); 
            } else {
                changeCurrency({ code: currency.codigo, value: currency.cotizacion});
            }
        }
    }, []);

    useEffect(() => {
        const filteredCurrencies = currencies.filter(currency => currency.code.toLowerCase() !== activeCurrency.code.toLowerCase());
        filteredCurrencies.unshift(activeCurrency);
        setSortedCcurrencies(filteredCurrencies);

        if (enableRealCurrencySymbol) {
            cookie.set(KEY_MONEDA, { codigo: activeCurrency.code, cotizacion: activeCurrency.value, codigoAMostrar: activeCurrency.codeShow });
        } else {
            cookie.set(KEY_MONEDA, { codigo: activeCurrency.code, cotizacion: activeCurrency.value });
        }
    }, [activeCurrency.code]);
 
    const handleClick = c => {
        changeCurrency(c);
        trackClick(`Selector moneda cambiado de ${activeCurrency.code} a ${c.code}`)
    }

    const currencyCode = enableRealCurrencySymbol ? activeCurrency.codeShow : activeCurrency.code;

    return (
        !isDesktop ?
            <div className={classes.currencyMobile }>
                <Dropdown 
                    data={sortedCcurrencies} 
                    dropdownTitle={currencyCode} 
                    onClick={handleClick} 
                    property={enableRealCurrencySymbol ? 'codeShow' : 'code'}
                    renderBorder={false}
                />
            </div>
        :
            <div>
                <Dropdown 
                    data={sortedCcurrencies} 
                    dropdownTitle={currencyCode} 
                    onClick={handleClick} 
                    property={enableRealCurrencySymbol ? 'codeShow' : 'code'}
                    renderBorder={false}
                />
            </div>
        
    )

}

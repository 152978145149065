const Devices = Object.freeze({
    Mobile: { name: 'mobile', regex: /iPhone|iPod|Android/i },
    Tablet: { name: 'tablet', regex: /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i},
    Desktop: { name: 'desktop', regex: /(.*?)/i}
});

const get = (userAgent) => {
    let device; 
    for (let name in Devices) {
        if (Devices[name].regex.test(userAgent)) {
            device = Devices[name];
            break;
        }
    }

    return device;
}

const sizes = {
    mobile: 'xs',
    tablet: 'sm',
    desktop: 'md'
};

export { Devices, sizes };
export default 
    (agent) => { 
        const userAgent = agent || navigator.userAgent;
        return get(userAgent);
    }
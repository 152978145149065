import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Icon from 'components/shared/icon';

import { useApplication } from 'hooks/application';

import { trackClick } from 'hooks/tracking';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

const ICONS = {
	'bus': 'directions_bus_filled',
	'plane': 'flight_takeoff',
	'ship': 'directions_boat',
	'cab': 'local_taxi',
	'car': 'car_rental',
	'suitcase': 'mode_of_travel',
}

export default ({ product, callback }) => {
	useStyles(classes);

	const { culture, host } = useApplication();
	const lng = culture.iso_code;

	let textKey =product.text;

	if(host && host=='trenpatagonico.plataforma10.com.ar' &&product.text=='Omnibus')
	{
		textKey='Omnibus_tren_patagonico';
	}

  	const { t } = useTranslation('product');
	const seoUrl = t(`product:${product.urlKey}`,{lng:lng});
	const text = t(`product:${textKey}`,{lng:lng});
	const baseURL =`https://${host}`;

	const Component = ({ children, ...rest }) => {		
		if(seoUrl === '') {			
			return <a href={baseURL} {...rest} > { children } </a>
		} else {
			return !product.externalLink 
			? <Link to={`/${seoUrl}`} {...rest}> { children } </Link>
			: <a href={seoUrl} {...rest} target='_blank'> { children } </a>
		}
	}

	return (
		<li 
			className={classes.product} 
			ref={callback} 
			onClick={() => trackClick(`Boton Producto ${text}`)}
		>
			<Component
				className={classes.button} 
				aria-label={text}
			>
				<i className={`material-icons-outlined ${classes.icon}`}>{ICONS[product.icon]}</i>
				<span className={classes.text}>{ text }</span>
				{/* <Icon 
					name={product.icon} 
					classes={product.isNew ? `${classes.icon} ${classes.iconNew}` : classes.icon } 
				/>
					<span data-testid='text' className={classes.text}>
						{
							product.isNew && 
								<span className={classes.newText}>
									{`¡${t('product:new',{lng:lng})}!`}
								</span>
						}
						{ text }
					</span> */}
			</Component>
		</li>
	);
}
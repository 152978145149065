import React from 'react';

import { Helmet } from 'react-helmet';
import Header from 'components/header';
import Products from 'components/products';
import GenericMessage from 'components/shared/genericMessage';
import Footer from 'components/footer';

import { LayoutProvider } from 'hooks/layout/layoutContext'

import useStyles from 'isomorphic-style-loader/useStyles'
import classes from 'components/style.module.scss';

export default ({ children }) => {
  useStyles(classes);
  const enableStrapi = process.env.FT_ENABLE_STRAPI_BUSES_GENERIC_MESSAGE;
  const enableNoAccessRobots = process.env.FT_ENABLE_NO_ACCESS_ROBOTS;

  return (
    <>
      <Helmet>
        { enableNoAccessRobots ? <meta name="robots" content="noindex, nofollow" /> : '' }
      </Helmet>
      
      <LayoutProvider>
        <Header />
        <Products />
        <GenericMessage endpoint={enableStrapi ? 'genericMessageBusesQuery':'Contenido/MensajeGenerico'} />
        <div className={classes.container}>
          {children}
        </div>
        <Footer />
      </LayoutProvider>
    </>
  )
}
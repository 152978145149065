import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/shared/icon';

import { trackClick } from 'hooks/tracking';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ data }) => {
	useStyles(classes, globalStyles);
	const { t } = useTranslation('follow');

	return (
		<div className={`${classes.icons} ${globalStyles.flexCenter}`}>
			<span className={classes.followUs}>
				{ t('followUs') }
			</span>
			<div className={globalStyles.flexJustifyCenter}>
				{
					data && data.map((network, index) =>
						<a 
							target='_blank'
							rel='noopener noreferrer'
							href={network.url}
							title={network.title}
							onClick={() => trackClick(`Link header red social ${network.title}`)}
							key={index}
							className={globalStyles.mlr5}
						>
							<Icon classes={classes.icon} name={`${network.icon}`} />
						</a>
					)
				}
			</div>
		</div>
	);
}
import { useApplication } from 'hooks/application';
import HeaderManager from './headerManager';


export default () => {
	const { country } = useApplication();
	const header = HeaderManager().getHeader(country.url);

	return (
		header
	);
}
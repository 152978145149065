import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import LeftContainer from 'components/header/leftContainer';
import Navbar from 'components/header/navbar';
import Country from 'components/header/leftContainer/country';
import MyAccount from 'components/header/myAccount';
import CustomDialog from 'components/shared/customDialog';

import { trackClick } from 'hooks/tracking';
import { ButtonsType } from 'shared/buttonsType';
import Button from 'components/shared/button';
import cookie from 'shared/cookie';

import getUser from 'shared/authenticatedUser';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from '../style.module.scss';
import globalStyles from 'styles/main.scss';
import '../translations';
import Currency from 'components/header/leftContainer/currency';

const HeaderAr = ( {parameters} ) => {
    const { isMobile, lng} = parameters;
    useStyles(classes, globalStyles);
	const { t } = useTranslation('header');

	const [showDialog, setShowDialog] = useState(false);
	const { authenticated } = getUser();
	const onDialogClose = () => window.location = `/${t('myPurchasesLink',{lng:lng})}`;

	const handleReturnButton = () => {
		if (!authenticated) {
			cookie.deleteAll();
			setShowDialog(true);
			trackClick('Boton devolucion pasaje header');
		} else {
			onDialogClose();
		}
	}
    return (
		<div className={classes.root}>
			<LeftContainer />
			<div className={classes.rightContainer}>
				<div className={classes.topContainer}>										
					{!isMobile &&
						<>
                            <Button 
                                customClasses={classes.customButton} 
                                onClick={handleReturnButton}>
                                { t('returnTicket',{lng:lng}) } 
                            </Button> 
                            <Button
                                customClasses={classes.customButton}
                                buttonsType={ButtonsType.ExternalLink}
                                to='https://p10-cdn.s3.amazonaws.com/p10botondearrepentimiento.pdf'
                            >
                                Botón de arrepentimiento
                            </Button>
						</>
                    }
					{isMobile && <Country />}
					{isMobile && <Currency />  }
					<MyAccount />				
					
				</div>
				<div>
					<Navbar>
                            <li>
                                <Link
                                    className={classes.link}
                                    to={`/${t('howtoShopLink',{lng:lng})}`}
                                    onClick={() => trackClick("Link Como Comprar Header")}>
                                    {t('howtoShop',{lng:lng})}
                                </Link>
                            </li>
                            <li>
                                <a
                                    className={classes.link}
                                    href={`/${t('joinOurNetLink',{lng:lng})}`}
                                    onClick={() => trackClick("Link Sumate A La Red Header")}
                                >
                                    {t('joinOurNet',{lng:lng})}
                                </a>
                            </li>
						<li>
							<a
								className={classes.link}
								href={`/${t('blogLink',{lng:lng})}`}
								onClick={() => trackClick("Link Blog Header")}>
								{t('blog',{lng:lng})}
							</a>
						</li>
						{
							isMobile &&
							<li>
								<Link
									className={classes.link}
									to={`/${t('helpLink',{lng:lng})}`}
									onClick={() => trackClick("Link Ayuda Header")}
								>
									{t('help',{lng:lng})}
								</Link>
							</li>
						}
					</Navbar>
				</div>
			</div>
			{
				showDialog &&
				<CustomDialog onClose={onDialogClose} title={t('attention',{lng:lng})}>
					<div className={globalStyles.flexColumn}>
						<span> {t('returnLoginMessage',{lng:lng})} </span>
					</div>
				</CustomDialog>
			}
		</div>
	);
} 

export default HeaderAr;
export default {
    urlBuses: '',
    urlServices: 'services',
    urlVuelos: 'flights-tickets',
    urlFluviales: 'ferries-tickets',
    urlRemises: 'cab-transfers',
    urlTransfers: 'transportation',
    urlCyT: 'tours-and-transportation',
    urlHoteles: 'hotels',
    urlViajesEspeciales: 'special-journeys',
    Omnibus: 'Bus',
    Omnibus_tren_patagonico: 'Tren Patagonico',
    Vuelos: 'Flights',
    Fluviales: 'Ferries',
    Remises: 'Cabs',
    Transfers: 'Transfers',
    CyT: 'Tours and transportation',
    Hoteles: 'Hotels',
    ViajesEspeciales: 'Special journeys',
    new: 'New'
}
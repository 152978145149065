import React from 'react';
import { Helmet } from 'react-helmet';

import Header from 'components/ow/header';
import Footer from 'components/ow/footer';
import Products from 'components/products';
import GenericMessage from 'components/shared/genericMessage';

import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles'
import classes from 'components/style.module.scss';

export default ({ children }) => {
  useStyles(classes);
  const { app, name } = useApplication();

  return (
    <div>
      <Helmet>        
        <meta name="robots" content="noindex, follow" />
          <style>{`
              :root {
                  --primary-main: ${app.mainColor};
                  --searchBox-button-bg-color: ${app.searchButtonColor};
                  --searchBox-button-bg-color-hover: ${app.searchButtonHoverColor};
                  --searchBox-button-font-color-hover: #000;
                  --services-badges-color: ${app.searchButtonColor};
                  --services-buy-button-color: ${app.searchButtonColor};
                  --destination-title-item-bg-color: ${app.mainColor};
              }
          `}
        </style>
      </Helmet>
      <Header />
      { !app.changeHeaderColor && ['rojoturismo', 'pasajes-ya'].indexOf(name) === -1  && <Products /> }
      <GenericMessage endpoint={'Contenido/MensajeGenericoOWs'} />
      <div className={classes.container}>
        { children }
      </div>
      <Footer />
    </div>
  )
}
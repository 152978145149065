export default {
    myAccount:'My account',
    myData:'Ny data',
    myPurchases:'Ny purchases',
    logout:'Logout',
    myDataLink:'/verperfil',
    myPurchasesLink:'/usuario/miscompras',
    logoutLink:'/autenticacion/logout',
    myAccountLink:'/login',
    welcome: 'Welcome '
}
import React, { useState, useRef, useEffect } from 'react';

import Product from 'components/products/product';
import Icon from 'components/shared/icon';

import { useApplication } from 'hooks/application';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import getEnabledResources from './resources';

export default () => {
	useStyles(classes);
	const { app, country, resources, type } = useApplication();

	const [translateValue, setTranslateValue] = useState(0);
	const [leftArrowEnabled, setLeftArrowEnabled] = useState(false);
	const [rightArrowEnabled, setRightArrowEnabled] = useState(false);
	const [showArrows, setShowArrows] = useState(false);
	const [arrowClicks, setArrowClicks] = useState(0);
	const [totalWidth, setTotalWidth] = useState(0);
	const [displayedElementsCount, setDisplayedElementsCount] = useState(0);
	const [products, setProducts] = useState([]);

	const buttonsContainerRef = useRef();
	const navbarRef = useRef();
	const barProducts = useRef(getEnabledResources(resources, country, type));

	useEffect(() => {
		const navbarWidth = buttonsContainerRef.current.clientWidth;
		const totalBarWidth = getDisplayedElements();
		
		if (totalBarWidth > navbarWidth) {
			setTotalWidth(80 + totalBarWidth - navbarWidth);
			setRightArrowEnabled(true);
			setShowArrows(true);
		}
	}, [])
	
	const moveArrow = getValues => {
		const 
			productsWidth = buttonsContainerRef.current.clientWidth,
			scrolledPixels = arrowClicks * productsWidth;

		if (totalWidth > scrolledPixels) {
			const params = (scrolledPixels + productsWidth + 40 >= totalWidth) ? getValues(true) : getValues();
			modifyState(params);
		}
	}

	const moveLeft = () => {
		moveArrow(sliderLimit => {
			const
				productsWidth = buttonsContainerRef.current.clientWidth,
				rightArrowEnabled = true;

			let translateXValue = translateValue + productsWidth,
				totalArrowClicks = arrowClicks + 1,
				leftArrowEnabled = true;

			if (sliderLimit) {
				translateXValue = 0;
				totalArrowClicks = 0;
				leftArrowEnabled = false;
			}

			return {
				translateXValue,
				totalArrowClicks,
				leftArrowEnabled,
				rightArrowEnabled
			}
		})
	}

	const moveRight = () => {
		moveArrow(sliderLimit => {
			const
				productsWidth = buttonsContainerRef.current.clientWidth,
				leftArrowEnabled = true;

			let translateXValue = translateValue - productsWidth,
				totalArrowClicks = arrowClicks + 1,
				rightArrowEnabled = true;

			if (sliderLimit) {
				const pixelsToMove = totalWidth + translateValue;
				translateXValue = translateValue - pixelsToMove;
				totalArrowClicks = 0;
				rightArrowEnabled = false;
			}

			return {
				translateXValue,
				totalArrowClicks,
				leftArrowEnabled,
				rightArrowEnabled
			}
		});
	}

	const modifyState = params => {
		const { translateXValue, totalArrowClicks, rightArrowEnabled, leftArrowEnabled } = params;

		setTranslateValue(translateXValue);
		setArrowClicks(totalArrowClicks);
		setRightArrowEnabled(rightArrowEnabled);
		setLeftArrowEnabled(leftArrowEnabled);
	}

	const getDisplayedElements = () => {
		const navbarWidth = navbarRef.current.clientWidth;
		let totalWidth = 0;

		products.forEach(prod => {
			totalWidth += prod.clientWidth;

			if (totalWidth <= navbarWidth) {
				let elements = displayedElementsCount;
				setDisplayedElementsCount(elements++);
			}
		});

		return totalWidth;
	}

	const addProduct = ref => {
		products.push(ref);
		setProducts(products);
	}

	const 
		leftArrowStyle = !leftArrowEnabled ? `${classes.icon} ${classes.disabled}` : `${classes.icon}`,
		rightArrowStyle = !rightArrowEnabled ? `${classes.icon} ${classes.disabled}` : `${classes.icon}`;

	return (
		<div className={app && app.changeProductsColor ? `${classes.navbar} ${classes.owNavbar}` : classes.navbar}>
			<div className={classes.root} ref={navbarRef}>
				{
					showArrows 
						? <div className={leftArrowStyle} onClick={moveLeft} id='left-arrow'>
							<Icon classes={classes.arrowIcon} name='angle-left' />
						  </div>
						: null
				}

				<div ref={buttonsContainerRef} className={classes.products}>
					<ul style={{ transform: `translateX(${translateValue}px)`}}>
						{
							barProducts.current.map((item, index) =>
								<Product 
									key={index} 
									icon={item.icon} 
									resource={item.resource} 
									product={item} 
									callback={addProduct}
								/>
							)
						}
					</ul>
				</div>

				{
					showArrows 
						? <div className={rightArrowStyle} onClick={moveRight} id='right-arrow'>
							<Icon classes={classes.arrowIcon} name='angle-right' />
						  </div>
						: null
				}
			</div>
		</div>
	);
}

const mapTerms = terms => {
    return terms.map(term => {
        const { Descripcion, Titulo } = term;

        return {
            title: Titulo,
            description: Descripcion
        }
    })
}

export default ({ CondicionesGenerales, Metadata }) => {
    return {
        terms: mapTerms(CondicionesGenerales),
        metadata: Metadata
    }
}
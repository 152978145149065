// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._10bdd5cf04aea1ec8560c0d855f7ede8-scss{background:var(--white-light);color:#2d3238;border:1px solid var(--grey-medium);padding:10px;font-size:1rem;border-radius:6px;line-height:20px;font-weight:bold;margin-bottom:5px;text-align:center}._9228c31414882d88dd2afdc4c35c3589-scss{background:#ffe66e;color:#2d3238;text-align:left}._8df99893672f146732a10a2e24a9f703-scss{background:var(--yellow-light);color:var(--black-light);text-align:left;-webkit-filter:drop-shadow(5px 5px 5px var(--grey-light));filter:drop-shadow(5px 5px 5px var(--grey-light))}._3cdce807ba25a2f987f0d00bdbceae51-scss{fill:var(--black-dark);display:inline-block;width:2rem;margin-right:10px}._5c25a55e22134a8253772813e53d51cd-scss{flex:1}\n", ""]);
// Exports
exports.locals = {
	"container": "_10bdd5cf04aea1ec8560c0d855f7ede8-scss",
	"warningContainer": "_9228c31414882d88dd2afdc4c35c3589-scss",
	"informationContainer": "_8df99893672f146732a10a2e24a9f703-scss",
	"icon": "_3cdce807ba25a2f987f0d00bdbceae51-scss",
	"textContainer": "_5c25a55e22134a8253772813e53d51cd-scss"
};
module.exports = exports;

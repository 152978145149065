// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2513191720cdb0b98c0c0b2a9fe98bba-scss,._186217c2bf89130956a402697c13a060-scss{padding:7px 10px;transition:all 0.5s ease-in-out;font-size:14px;font-weight:bold;border-radius:25px;text-align:center;text-transform:uppercase;outline:none;display:block}._2513191720cdb0b98c0c0b2a9fe98bba-scss:disabled,._186217c2bf89130956a402697c13a060-scss:disabled{color:rgba(0,0,0,0.26);box-shadow:none;background-color:rgba(0,0,0,0.12);cursor:default;pointer-events:none;border:0}._186217c2bf89130956a402697c13a060-scss{background-color:var(--primary-main);color:var(--white-light);border:1px solid transparent}._186217c2bf89130956a402697c13a060-scss:hover{color:var(--primary-main);background:var(--white-light);border-color:var(--primary-main)}\n", ""]);
// Exports
exports.locals = {
	"shared": "_2513191720cdb0b98c0c0b2a9fe98bba-scss",
	"buttonFilled": "_186217c2bf89130956a402697c13a060-scss"
};
module.exports = exports;

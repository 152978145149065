export default {
    urlBuses: '',
    urlServices: 'servicios',
    urlVuelos: 'pasajes-vuelos',
    urlFluviales: 'pasajes-fluviales',
    urlRemises: 'traslados-remises',
    urlTransfers: 'traslados',
    urlCyT: 'circuitos-y-traslados',
    urlHoteles: 'hoteles',
    urlViajesEspeciales: 'viajes-especiales',
    Omnibus: 'Ómnibus',
    Omnibus_tren_patagonico: 'Tren Patagonico',
    Vuelos: 'Vuelos',
    Fluviales: 'Fluviales',
    Remises: 'Remises',
    Transfers: 'Transfers',
    CyT: 'Circuitos y traslados',
    Hoteles: 'HOTELES',
    ViajesEspeciales: 'Viajes especiales',
    new: 'Nuevo'
}
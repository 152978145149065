import { cookie } from 'shared';

export default () => {
    const authenticated = cookie.get('login') === 'true' ? true : false;
    const username = cookie.get('USERNAME');
    const email = cookie.get('email');
    let name, surname;

    if(username) {
        const fullName = username.split('|');
        name = fullName[0];
        surname = fullName[1];
    }

    return {
        authenticated,
        name,
        surname,
        email
    }
}
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/button';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';
import './translations';

export default ({ title, children, customClasses, onClose }) => {
  useStyles(classes, globalStyles);
  const { t } = useTranslation('dialog');

  const classesContent = customClasses 
    ? `${classes.dialogContainer} ${customClasses.content}`
    : classes.dialogContainer;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.dialog}>
          <div className={classes.header}>
            <span className={classes.title}>
              { title }
            </span>
          </div>
          <div className={classesContent}>
            { children }
          </div>
          <div className={`${globalStyles.flexCenter} ${globalStyles.m10}`}>
            <Button onClick={() => onClose()}>
              { t('accept') }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
import React, {createContext,useState,useEffect} from 'react';

import mapGenericMessage from 'hooks/cms/mappers/mapGenericMessage';
import mapFooterPcom from 'hooks/cms/mappers/mapFooterPcom';
import mapLogo from 'hooks/cms/mappers/mapLogo';

import useRedis from 'hooks/cms/redis';
import { useApplication } from 'hooks/application';

const LayoutContext = createContext({});

const LayoutProvider  = ({children}) => {
    const {getCache} = useRedis();
    const {idPv} = useApplication();
    const [data, setData] = useState(null);
    
    const getContent = async ()=>{
        const cmsContent = await getCache({key:'layoutQuery',idPv:idPv});
      
        const content = {
            footer:mapFooterPcom(cmsContent),
            genericMessage:mapGenericMessage(cmsContent),
            logo:mapLogo(cmsContent)
        };
        
        setData(content)
    }

    useEffect(()=> {
        if(process.env.FT_ENABLE_STRAPI_CMS && process.env.FT_ENABLE_STRAPI_LAYOUT) getContent()
    },[])

    return (
        <LayoutContext.Provider value={data}>
            {children}
        </LayoutContext.Provider>
    )
}

export default LayoutContext;

export {LayoutProvider};
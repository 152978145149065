module.exports.features = function ()
{
    return {
        "enableServices":process.env.FT_ENABLE_SERVICE, 
        "enableCurrency":process.env.FT_ENABLE_CURRENCY, 
        "enableWebp": process.env.FT_ENABLE_WEBP, 
        "enablePWA": process.env.FT_ENABLE_PWA, 
        "enableElasticSearch": process.env.FT_ENABLE_ELASTICSEARCH,
        "enableFlight":process.env.FT_ENABLE_FLIGHT,
        "enableRealCurrencySymbol": process.env.FT_ENABLE_REAL_CURRENCY_SYMBOL
    }
};


// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._5f5f72c0c92a155c133e4fb334648591-scss{width:100%;background:#fff;position:absolute;left:0;top:100%;z-index:1;border-top:1px solid #e7e7e7}._5f5f72c0c92a155c133e4fb334648591-scss ul li{line-height:20px;border-bottom:1px solid var(--grey-light)}._5f5f72c0c92a155c133e4fb334648591-scss ul li:last-child{border-bottom:0}._5f5f72c0c92a155c133e4fb334648591-scss ul li a{display:block;padding:10px 15px;border-radius:4px;font-weight:900;text-transform:uppercase;font-size:14px}._7634084105b60a265fe0cbf941df30de-scss{-webkit-animation:_78b871afff5de588874c11e187618bc1-scss 0.5s ease-in-out;animation:_78b871afff5de588874c11e187618bc1-scss 0.5s ease-in-out}@-webkit-keyframes _78b871afff5de588874c11e187618bc1-scss{0%{opacity:0}100%{opacity:1}}@keyframes _78b871afff5de588874c11e187618bc1-scss{0%{opacity:0}100%{opacity:1}}\n", ""]);
// Exports
exports.locals = {
	"items": "_5f5f72c0c92a155c133e4fb334648591-scss",
	"navbar": "_7634084105b60a265fe0cbf941df30de-scss",
	"fadeIn": "_78b871afff5de588874c11e187618bc1-scss"
};
module.exports = exports;

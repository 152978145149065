const mapImages = images => {
    return images.map(image => {
        const { ImgAlt, UrlImagen, ImgTitle } = image;
    
        return {
            url: UrlImagen,
            alt: ImgAlt,
            title: ImgTitle
        }
    })
}

export default ({ Descripcion, ImagenDerecha, ImagenIzquierda, Metadata, Subtitulo, Titulo }) => ({
    description: Descripcion,
    metadata: Metadata,
    images: mapImages([ImagenIzquierda, ImagenDerecha]),
    title: Titulo,
    subtitle: Subtitulo
});
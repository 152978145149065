import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

const useError = () => {
    const [error, setError] = useContext(ErrorContext);
    return { error, setError };
}

const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(false);
    
    return(
        <ErrorContext.Provider value={[error, setError]}>
            { children }
        </ErrorContext.Provider>
    )
}

export { useError, ErrorProvider };
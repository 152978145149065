const mapCities = cities => {
    return cities.map(city => {
        const { Id, Nombre } = city;
 
        return {
            id: Id,
            name: Nombre
        }
    })
};

const mapPvs = (pvs, imageUrl) => {
    return pvs.map(pv => {
        const { 
            Destacado,  Imagen, Alias, Domicilio, HorariosDeAtencion,
            Latitud, Longitud, TieneTarjetasCredito, TieneTarjetasDebito, VendeConTarjetaCredito,
            VendeConTarjetaCreditoWeb, TieneVuelosActivado, IdPV
        } = pv;
 
        return {
            highlighted: Destacado,
            image: `${imageUrl}${Imagen}`, 
            alias: Alias,
            address: Domicilio,
            schedule: HorariosDeAtencion,
            latitude: Latitud,
            longitude: Longitud,
            idPv: IdPV,
            hasCreditCard: TieneTarjetasCredito,
            hasDebitCard: TieneTarjetasDebito,
            sellsWithCreditCard: VendeConTarjetaCredito,
            sellsWithCreditCardWeb: VendeConTarjetaCreditoWeb,
            hasFlightsActivated: TieneVuelosActivado
        }
    })
};

const mapDistrictPvs = (pvs, imageUrl) => {
    return pvs.map(pv => {
        const { NombreBarrio,  Pvs } = pv;
 
        return {
            districtName: NombreBarrio,
            pvs: mapPvs(Pvs, imageUrl)
        }
    })
};

const mapGroupedPvs = (pvs, imageUrl) => {
    return pvs.map(pv => {
        const { NombreLocalidad,  PVsPorBarrios } = pv;
 
        return {
            cityName: NombreLocalidad,
            pvsByDistrict: mapDistrictPvs(PVsPorBarrios, imageUrl)
        }
    })
};

export default data => {
    const { 
        AltImagenTarjetas, IdProvinciaSeleccionada, ImagenTarjetas, Metadata,
        Provincias, PvsAgrupados, UrlBaseImagenPvs
    } = data.CompraPresencialViewModel;

    const cities = mapCities(Provincias);

    return {
        cities,
        selectedCity: cities.find(city => city.id === IdProvinciaSeleccionada),
        cardsImage: ImagenTarjetas,
        cardsImageAlt: AltImagenTarjetas,
        pvs: mapGroupedPvs(PvsAgrupados, UrlBaseImagenPvs),
        baseUrlPvs: UrlBaseImagenPvs,
        metadata: Metadata
    }
};
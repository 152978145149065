export default {
    myAccount:'Minha conta',
    myData:'Neus datos',
    myPurchases:'Minhas compras',
    logout:'Sair',
    myDataLink:'/verperfil',
    myPurchasesLink:'/usuario/miscompras',
    logoutLink:'/autenticacion/logout',
    myAccountLink:'/login',
    welcome: 'Bem-vindo '
}
import React, { createContext, useContext, useReducer } from 'react';

const ApplicationContext = createContext();

const useApplication = () => {
    const [application, setApplication] = useContext(ApplicationContext);

    return { ...application, setApplication };
}

const ApplicationProvider = ({application, children}) => {
    const reducer = (state, action) => {
        switch(action.type) {
            case 'activeCurrency':
                return { ...state, activeCurrency: action.value }
            default:
                return state;
        }
    };
    
    return(
        <ApplicationContext.Provider value={useReducer(reducer, application)}>
            { children }
        </ApplicationContext.Provider>
    )
}

export { useApplication, ApplicationProvider };
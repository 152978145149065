export default (country, type) => ([
    {
        resource: 'buses',
        icon: 'bus',
        text: 'Omnibus',
        urlKey: type.name === 'ow' ? 'urlServices' : 'urlBuses',
        isNew: false,
        externalLink: false
    },
    {
        resource: 'Layout/_BotonVuelos',
        icon: 'plane',
        text: 'Vuelos',
        urlKey: 'urlVuelos',
        isNew: false,
        externalLink: country === 'br' ? true : false
    },
    {
        resource: 'Layout/_BotonFluviales',
        icon: 'ship',
        text: 'Fluviales',
        urlKey: 'urlFluviales',
        isNew: false,
        externalLink: false
    },
    {
        resource: 'Layout/_BotonRemises',
        icon: 'cab',
        text: 'Remises',
        urlKey: 'urlRemises',
        isNew: true,
        externalLink: false
    },
    {
        resource: 'Layout/_BotonProductoTransfer',
        icon: 'car',
        text: 'Transfers',
        urlKey: 'urlTransfers',
        isNew: false,
        externalLink: false
    },
    {
        resource: 'Layout/_BotonProductoCyT',
        icon: 'suitcase',
        text: 'CyT',
        urlKey: 'urlCyT',
        isNew: false,
        externalLink: false
    },
    {
        resource: 'Layout/_BotonProductoHoteles',
        icon: 'hotel',
        text: 'Hoteles',
        urlKey: 'urlHoteles',
        isNew: false,
        externalLink: false
    },
    /* {
        resource: 'Layout/_BotonProductoViajesEspeciales',
        icon: 'users',
        text: 'ViajesEspeciales',
        urlKey: 'urlViajesEspeciales',
        isNew: false,
        externalLink: false
    }, */
]);
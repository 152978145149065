import mapMetaData from "./mapMetaData";
import mapMostSearchedTickets from "./mapMostSearchedTickets";


export const mapComments = comments => {
    return comments.map(comment => {
        const { Comentario, Fecha, Usuario, Puntaje} = comment;
        return {
            text: Comentario,
            date: Fecha,
            username: Usuario,
            rating: Puntaje
        }
    })
}

const mapStyles = styles => {
    const { colorFondoBuscador,
    colorLetraBuscador,
    colorFondoBotonBuscador,
    colorLetraBotonBuscador,
    colorFondoTituloDestinosItem } = styles;

    return {
        searchBoxBgColor: colorFondoBuscador,
        searchBoxFontColor: colorLetraBuscador,
        searchBoxButtonBgColor: colorFondoBotonBuscador,
        searchBoxButtonFontColor: colorLetraBotonBuscador,
        destinationTitleItemBgColor: colorFondoTituloDestinosItem,
    }
}

const mapDescription = data => {
    const { nombre,imagenOmnibus,imagenOmnibus2,imagenOmnibus3,descripcion } = data;

    return {
        name: nombre,
        description: descripcion,
        imgOmnibus: imagenOmnibus?.imagen.map(i => i.url),
        altImgOmnibus: imagenOmnibus?.imagen[0]?.alternativeText,
        imgTopRight: imagenOmnibus2?.imagen.map(i => i.url),
        altImgTopRight: imagenOmnibus2?.imagen[0]?.alternativeText,
        imgBottomRight: imagenOmnibus3?.imagen.map(i => i.url),
        altImgBottomRight: imagenOmnibus3
    }
}

const mapLandingPage = landing => {
    const { mostrarRatings, 
        pais, 
        logo,
        idEmpresaTransporte,
        idGrupoEconomico,
        urlRedireccion,
        titulo,
        descripcion,
        pasajesMasBuscados,
        metadata } = landing;

    return {
        idTransportationCompany: idEmpresaTransporte,
        idEconomicGroup: idGrupoEconomico,
        logo: logo,
        metadata: mapMetaData(metadata),
        description: mapDescription(descripcion),
        mostSearchedTickets: mapMostSearchedTickets(pasajesMasBuscados),
        title: titulo,
        country: pais.idPais,
        redirectionUrl: urlRedireccion,
        showRating: mostrarRatings,
        active: true
    }
}

const mapLandingLayoutPage = landingLayout => {
    const { mostrarRatings, 
        pais, 
        logo,
        urlRedireccion,
        titulo,
        descripcion } = landingLayout;

    return {
        logo: logo.map(i => i.url),
        description: {
            name: descripcion.nombre
        },
        title: titulo,
        country: pais.idPais.toLowerCase(),
        redirectionUrl: urlRedireccion,
        showRating: mostrarRatings,
        active: true
    }
}

export const mapLanding = data =>{
    if(!data?.landings || data?.landings.length == 0) return null;

    const { estilos } = data.landings[0];

    return  {
        landing: mapLandingPage(data.landings[0]),
        styles: mapStyles(estilos)
    }
};

export const mapLandingLayout = data =>{
    if(!data?.landings || data?.landings.length == 0) return null;

    const { estilos } = data.landings[0];

    return  {
        landing: mapLandingLayoutPage(data.landings[0]),
        styles: mapStyles(estilos)
    }
};
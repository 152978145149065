import mapDeal from './mapDeal';

export default featureDeal => {
    const deal = mapDeal(featureDeal);

    return {
        ...deal,
        subtitle: featureDeal.Detalle,
        informativeText: featureDeal.Dias
    }
}
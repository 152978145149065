const mapGenericMessage = response => {
    const { mensajeGenerico } = response.layouts[0]
 
    return  mensajeGenerico && mensajeGenerico.activo
    ? {
        type: mensajeGenerico.tipo,
        text: mensajeGenerico.texto
    }
    : null
 }
 
 export default mapGenericMessage
// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._99efbddff1bc3eda92427911f1635045-scss{padding:10px;margin-bottom:0 !important;border-radius:0}._64e250af14d574a1a1430ed32d1598b8-scss{position:relative}._64e250af14d574a1a1430ed32d1598b8-scss .cb16a7e171b2cc37a1de6d6bca8f8562-scss{position:absolute;top:7px;right:3px;cursor:pointer}._64e250af14d574a1a1430ed32d1598b8-scss .cb16a7e171b2cc37a1de6d6bca8f8562-scss .dcfceace8a20035773070bea8cf464fa-scss{display:block;width:25px}\n", ""]);
// Exports
exports.locals = {
	"root": "_99efbddff1bc3eda92427911f1635045-scss",
	"container": "_64e250af14d574a1a1430ed32d1598b8-scss",
	"iconContainer": "cb16a7e171b2cc37a1de6d6bca8f8562-scss",
	"icon": "dcfceace8a20035773070bea8cf464fa-scss"
};
module.exports = exports;

import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';

import Loading from 'components/shared/loading';

import { useApplication } from 'hooks/application';
import LayoutPcom from '../views/shared/layout/default';
import LayoutLanding from '../views/shared/layout/landing';
import LayoutOW from '../views/shared/layout/ow';
import {HomeBusesProvider} from 'hooks/homeBuses/homeBusesContext'
import os from "os";
const hostname = os.hostname();

const BusesServices = loadable(() => import(
  /* webpackChunkName: 'BusesServices'  */
  '../views/buses/services'
));

const BusesResults = loadable(() => import(
  /* webpackChunkName: 'BusesResults'  */
  '../views/buses/results'
));

const Offline = loadable(() => import(
  /* webpackChunkName: 'Offline'  */
  '../views/offline'
), { fallback: <Loading /> });

const Ferries = loadable(() => import(
  /* webpackChunkName: 'Ferries'  */
  '../views/ferries/home'
), { fallback: <Loading /> });

const FerriesServices = loadable(() => import(
  /* webpackChunkName: 'FerriesServices'  */
  '../views/ferries/services'
));

const FerriesResults = loadable(() => import(
  /* webpackChunkName: 'FerriesResults'  */
  '../views/ferries/results'
));

const Flights = loadable(() => import(
  /* webpackChunkName: 'Flights'  */
  '../views/flights/home'
), { fallback: <Loading /> });

const FlightsServices = loadable(() => import(
  /* webpackChunkName: 'FlightsServices'  */
  '../views/flights/services'
));

const Circuits = loadable(() => import(
  /* webpackChunkName: 'Circuits'  */
  '../views/c&t/circuits/home'
), { fallback: <Loading /> });

const Transfers = loadable(() => import(
  /* webpackChunkName: 'Transfers'  */
  '../views/c&t/transfers/home'
), { fallback: <Loading /> });

const CyTResults = loadable(() => import(
  /* webpackChunkName: 'CyTResults'  */
  '../views/c&t/results'
));

const CyTDetail = loadable(() => import(
  /* webpackChunkName: 'CyTDetail'  */
  '../views/c&t/detail'
), { fallback: <Loading /> });

const Hotels = loadable(() => import(
  /* webpackChunkName: 'Hotels'  */
  '../views/hotels/home'
), { fallback: <Loading /> });

const News = loadable(() => import(
  /* webpackChunkName: 'News'  */
  '../views/news'
), { fallback: <Loading /> });

const ContentDetail = loadable(() => import(
  /* webpackChunkName: 'ContentDetail'  */
  '../views/shared/contentDetail'
), { fallback: <Loading /> });

const NotFound = loadable(() => import(
  /* webpackChunkName: 'NotFound'  */
  '../views/notFound'
), { fallback: <Loading /> });

const NotFoundRedirect = loadable(() => import(
  /* webpackChunkName: 'NotFound'  */
  '../views/notFound/redirect'
), { fallback: <Loading /> });

const Deals = loadable(() => import(
  /* webpackChunkName: 'Deals'  */
  '../views/deals'
), { fallback: <Loading /> });

const RedirectToWordPress = loadable(() => import(
  /* webpackChunkName: 'Terminal'  */
  '../components/shared/redirectToWordPress'
), { fallback: <Loading /> });

const WhatIsP10 = loadable(() => import(
  /* webpackChunkName: 'WhatIsP10'  */
  '../views/whatIsP10'
), { fallback: <Loading /> });

const Landing = loadable(() => import(
  /* webpackChunkName: 'Landing'  */
  '../views/landing'
), { fallback: <Loading /> });

const Media = loadable(() => import(
  /* webpackChunkName: 'Media'  */
  '../views/media'
), { fallback: <Loading /> });

const Jobs = loadable(() => import(
  /* webpackChunkName: 'Jobs'  */
  '../views/jobs'
), { fallback: <Loading /> });

const GeneralConditions = loadable(() => import(
  /* webpackChunkName: 'GeneralConditions'  */
  '../views/generalConditions'
), { fallback: <Loading /> });

const QualityManagement = loadable(() => import(
  /* webpackChunkName: 'QualityManagement'  */
  '../views/qualityManagement'
), { fallback: <Loading /> });

const TransportCompanies = loadable(() => import(
  /* webpackChunkName: 'TransportCompanies'  */
  '../views/transportCompanies'
), { fallback: <Loading /> });

const Terminals = loadable(() => import(
  /* webpackChunkName: 'Terminals'  */
  '../views/terminals'
), { fallback: <Loading /> });

const Help = loadable(() => import(
  /* webpackChunkName: 'Help'  */
  '../views/help'
), { fallback: <Loading /> });

const InfoDev = loadable(() => import(
  /* webpackChunkName: 'InfoDev'  */
  '../views/infoDev'
), { fallback: <Loading /> });


const LandingsCompanies = loadable(() => import(
  /* webpackChunkName: 'LandingsCompanies'  */
  '../views/companies'
), { fallback: <Loading /> });

const SpecialJourneys = loadable(() => import(
  /* webpackChunkName: 'SpecialJourneys'  */
  '../views/specialJourneys'
), { fallback: <Loading /> });

const JoinOurNet = loadable(() => import(
  /* webpackChunkName: 'JoinOurNet'  */
  '../views/joinOurNet'
), { fallback: <Loading /> });

const JoinOurNetCompanies = loadable(() => import(
  /* webpackChunkName: 'JoinOurNetCompanies'  */
  '../views/joinOurNet/companies'
), { fallback: <Loading /> });

const JoinOurNetAgencies = loadable(() => import(
  /* webpackChunkName: 'JoinOurNetAgencies'  */
  '../views/joinOurNet/agencies'
), { fallback: <Loading /> });

const JoinOurNetEntrepreneur = loadable(() => import(
  /* webpackChunkName: 'JoinOurNetEntrepreneur'  */
  '../views/joinOurNet/entrepreneur'
), { fallback: <Loading /> });

const HowToBuy = loadable(() => import(
  /* webpackChunkName: 'HowToBuy'  */
  '../views/howToBuy'
), { fallback: <Loading /> });

const ElectronicPurchase = loadable(() => import(
  /* webpackChunkName: 'ElectronicPurchase'  */
  '../views/howToBuy/electronicPurchase'
), { fallback: <Loading /> });

const MobilePurchase = loadable(() => import(
  /* webpackChunkName: 'MobilePurchase'  */
  '../views/howToBuy/mobilePurchase'
), { fallback: <Loading /> });

const PersonalPurchase = loadable(() => import(
  /* webpackChunkName: 'PersonalPurchase'  */
  '../views/howToBuy/personalPurchase'
), { fallback: <Loading /> });

const Home = loadable(() => import(
  /* webpackChunkName: 'Home'  */
  '../views/buses/home'
), { fallback: <Loading /> });

const Cabs = loadable(() => import(
  /* webpackChunkName: 'Cabs'  */
  '../views/cabs/home'
), { fallback: <Loading /> });

export default () => {
  const { app,type, culture, idPv, country } = useApplication();
  const {showHowToBuy} = app;
  const location = useLocation()
  const apps = {
    pcom: LayoutPcom,
    landing: LayoutLanding,
    ow: LayoutOW
  }

  let Layout = apps[type.name];

  useEffect(() => {
     // clean up controller
     let isSubscribed = true;
     Layout = apps[type.name];
    return () => (isSubscribed=false);
  }, [type.name])

  const HomeBuses = () => {
    return (
      <HomeBusesProvider>
        <Home />
      </HomeBusesProvider>
    )
  }

  return (
    <Layout>
      <Switch>
        {/* HOME */}  

        <Route exact path='/' component={HomeBuses} />
        <Route exact path='/(pasajes-micro|bus-tickets|passagens-onibus)' component={HomeBuses} />
        {/* BUSES */}
        <Route exact path='/(servicios|services|servicos)/(buscar-pasajes|search-tickets|procurar-passagens)/:departureName/:destinationName/:departurePadId/:destinationPadId/:departureDate/:returnDate/:adults?/:minors?/:infants?' component={BusesResults} />
        <Route exact path='/(servicios|services|servicos)/(buscar-pasajes|search-tickets|procurar-passagens)/:departureName/:destinationName/:departurePadId/:destinationPadId/:adults?/:minors?/:infants?' component={BusesResults} />
        <Route exact path='/(servicios|services|servicos)/(buscar|search|procurar)/:departurePadId/:destinationPadId/:departureDate/:returnDate/:adults?/:minors?/:infants?' component={BusesResults} />
        <Route exact path='/(servicios|services|servicos)/(buscar|search|procurar)/:departurePadId/:destinationPadId/:departureDate?' component={BusesServices} />
        <Route exact path='/(servicios|services|servicos)' component={BusesServices} />

        
        {/* VUELOS */}
        <Route exact path='/(pasajes-vuelos|flights-tickets|passagens-voos)' component={Flights} />
        <Route exact path='/(vuelos|flights|voos)/(buscar|search|procurar)/:departurePadId/:destinationPadId/:departureDate/:returnDate?/:adults/:children/:infants' component={FlightsServices} />
      
        {/* FLUVIALES */}
        <Route exact path='/(pasajes-fluviales|boats-tickets|passagens-navios)' component={Ferries} />
        <Route exact path='/(fluviales|boats|navios)/(buscar-pasajes|search-tickets|procurar-passagens)/:departureName/:destinationName/:departurePadId/:destinationPadId/:departureDate/:returnDate/:adults/:minors/:infants' component={FerriesResults} />
        <Route exact path='/(fluviales|boats|navios)/(buscar-pasajes|search-tickets|procurar-passagens)/:departureName/:destinationName/:departurePadId/:destinationPadId' component={FerriesServices} />

        {/* REMISES */}
        <Route exact path='/(traslados-remises|cab-transfers|traslados-remises)' component={Cabs} />

        {/* TRANSFERS */}
        <Route exact path='/(traslados|transportation|transfer)' component={Transfers} />
        <Route exact path='/(traslados|transportation|transfer)/:destinationName' component={CyTResults} />
        <Route exact path='/(traslados|transportation|transfer)/:destinationName/:circuitName' component={CyTDetail} />

        {/* CIRCUITOS Y TRASLADOS */}
        <Route exact path='/(circuitos-y-traslados|tours-and-transportation|transfer-e-passeios)' component={Circuits} />
        <Route exact path='/(circuitos-y-traslados|tours-and-transportation|transfer-e-passeios)/(buscar|search|procurar)/:destinationId/:destinationName' component={CyTResults} />
        <Route exact path='/(circuitos-y-traslados|tours-and-transportation|transfer-e-passeios)/(detalle|detail|detalhe)/:destinationId/:circuitId/:destinationName/:circuitName' component={CyTDetail} />

        {/* HOTELES */}
        <Route exact path='/(hoteles|hotels|hoteis)' component={Hotels} />

        {/* SIN CONEXION */}
        <Route exact path='/(sin-conexion|offline|sem-conexao)' component={Offline} />

        {/* AYUDA */}
        {
          showHowToBuy &&
          <Route exact path='/(como-comprar|how-to-shop|como-comprar)/(ayuda|help|ajuda)' component={Help}/>
        }
        <Route exact path='/(ayuda|help|ajuda)' component={Help} />

        {/* TERMINALES */}
        <Route exact path='/(terminales-micro|bus-stations|rodoviarias-onibus|terminales-terrestres)' component={RedirectToWordPress} />
 
        {/* DETALLE TERMINAL */}
        <Route exact path='/(terminales-micro|bus-stations|rodoviarias-onibus|terminales-terrestres)/:name' component={RedirectToWordPress} />

        {/* DETALLE TERMINAL */}
        <Route exact path='/(terminales-micro|bus-stations|rodoviarias-onibus|terminales-terrestres)/:country/:name' component={RedirectToWordPress} />

        {/* COMO COMPRAR */}
        <Route exact path='/(como-comprar|how-to-shop|como-comprar)' component={HowToBuy} />
        
        {/* COMPRA PRESENCIAL */}
        <Route exact path='/(centros-de-atencion|customer-service-center)/:id/:city' component={PersonalPurchase} />
        
        {/* COMPRA ELECTRONICA */}
        {
          showHowToBuy &&
          <Route exact path='/(como-comprar|how-to-shop|como-comprar)/(electronica|eletronic|eletronica)' component={ElectronicPurchase} />
        }
        
        {/* COMPRA MOBILE */}
        <Route exact path='/(como-comprar|how-to-shop|como-comprar)/(mobile|mobile|celular)' component={MobilePurchase} />
        
        {/* QUE ES PLATAFORMA 10 */}
        <Route exact path='/(que-es-plataforma-10|what-is-plataforma-10|o-que-e-plataforma-10)' component={WhatIsP10} />

        {/* EMPRESAS DE TRANSPORTE AR - BR - USA*/}
        <Route exact path='/(informacion-para-empresas-de-transporte|transport-companies|informacao-para-empresas-de-transporte)' component={TransportCompanies} />
        {/* EMPRESAS DE TRANSPORTE PY*/}
        <Route exact path='/(empresas-de-omnibus)' component={() => { 
            window.location.href = `https://${hostname}${location.pathname}`; 
            return null;
         }} />

        {/* GESTION DE CALIDAD */}
        <Route exact path='/(gestion-de-calidad|quality-management|gestao-de-qualidade)' component={QualityManagement} />

        {/* SUMATE A LA RED */}
        <Route exact path='/(informacion-para-centros-de-atencion|customer-service-center|informacao-para-centros-de-atencao)' component={JoinOurNet} />
        <Route exact path='/(informacion-para-centros-de-atencion|customer-service-center|informacao-para-centros-de-atencao)/(empresas|companies|empresas)' component={JoinOurNetCompanies} />
        <Route exact path='/(informacion-para-centros-de-atencion|customer-service-center|informacao-para-centros-de-atencao)/(agencias-de-turismo|tourist-agencies|agencias-de-viagem)' component={JoinOurNetAgencies} />
        <Route exact path='/(informacion-para-centros-de-atencion|customer-service-center|informacao-para-centros-de-atencao)/(otros|miscellaneous|diversos)' component={JoinOurNetEntrepreneur} />

        {/* EMPLEOS */}
        <Route exact path='/(empleos|work-in-plataforma-10|oportunidades-na-plataforma-10)' component={Jobs} />

        {/* VIAJES ESPECIALES */}
        <Route exact path='/(viajes-especiales|special-journeys|viagens-especiais)' component={SpecialJourneys}/>

        {/* NOVEDADES*/}
        <Route exact path='/(novedades|news|novidades)' component={() => { 
          const supportedCountries = ['.com.ar', '.com.py', '.com.pe', '.com.br'];
          const shouldRedirect = supportedCountries.some(domain => hostname.includes(domain));

          if (shouldRedirect) {
            window.location.href = `https://${hostname}${location.pathname}`; 
            return null;
          }else{
            return <News/>;
          }
          }} />

        <Route 
          exact 
          path='/(novedad|news|novidade)/:name' 
          component={props => <ContentDetail {...props} type='news' query='newsDetailsQuery' queryType='novedads' /> }
        />

        {/* Listado de Landings */}
        <Route exact path='/(empresas-de-transporte|joined-transport-companies|empresas-de-transporte)' component={LandingsCompanies}/>
        
        {/* MEDIOS */}
        <Route exact path='/(medios|media|meios)' component={Media}/>
        
        {/* CONDICIONES GENERALES */}
        {
          showHowToBuy &&
          <Route exact path='/(como-comprar|how-to-shop|como-comprar)/(condiciones-generales|general-conditions|condicoes-gerais)' component={GeneralConditions}/>
        }
        <Route exact path='/(condiciones-generales|general-conditions|condicoes-gerais)' component={GeneralConditions}/>

        {/* PROMOCIONES */}
        <Route exact path='/(promociones|promotions|promocoes)'  component={() => { 
          window.location.href = `https://${hostname}${location.pathname}`; 
          return null;
          }} />
        <Route 
          exact 
          path='/(promocion|promotion|promocao)/:name' 
          component={(props) => <ContentDetail {...props}
          type='promotions'
          query='promotionDetailsQuery' queryType='promocions' /> }
        />
        
        {/* Landing */}
        <Route exact path='/(pasajes|passagens)/:landingName' component={Landing} />

        {/* Pagina no encontrada */}
        <Route exact path='/(pagina-no-encontrada|page-not-found|pagina-nao-encontrada)' component={NotFound} />
      
        <Route path='/*' component={NotFoundRedirect} />

      </Switch>
    </Layout>
  )
};

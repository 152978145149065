import i18next from 'i18next';

import es from './es';
import pt from './pt';
import en from './en';
import pe from './pe';

i18next.addResourceBundle('es', 'header', es);
i18next.addResourceBundle('es-PE', 'header', pe);
i18next.addResourceBundle('en', 'header', en);
i18next.addResourceBundle('pt', 'header', pt);
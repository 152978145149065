import React, { useEffect } from 'react';

import { hydrate, render } from 'react-dom'

import { BrowserRouter } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import { loadableReady } from '@loadable/component'

import StyleContext from 'isomorphic-style-loader/StyleContext'

import App from './components/index';
import ScrollToTop from 'components/scrollTop'

import { ApplicationProvider } from 'hooks/application';
import { ErrorProvider } from 'hooks/error';

const initState = window.APPLICATION !== null && window.APPLICATION !== undefined
  ? window.APPLICATION
  : (() => {
      console.error(
        'initState rompe porque application llega como undefined: ',
        JSON.stringify(window.APPLICATION));
      window.location.reload();
    })();

delete window.APPLICATION;

const history = createBrowserHistory();

const Client = () => {
	useEffect(() => {
		const ssr = document.getElementById('ssr');
		if (ssr && ssr.parentNode) {
			ssr.parentNode.removeChild(ssr);
    }
    
		const initState = document.getElementById('initState');
		if (initState && initState.parentNode) {
			initState.parentNode.removeChild(initState);
		}
  }, []);
  
  const insertCss = (...styles) => {
    const removeCss = styles.map(style => style._insertCss())
    return () => removeCss.forEach(dispose => dispose())
  }

  return (
    <ApplicationProvider application={initState.application}>
      <ErrorProvider>
        <BrowserRouter history={history}>
          <StyleContext.Provider value={{ insertCss }}>
            <ScrollToTop />
            <App />
          </StyleContext.Provider>
        </BrowserRouter>
      </ErrorProvider>
    </ApplicationProvider>
  )
}

const renderMethod = module.hot ? render : hydrate;

loadableReady(() => {
  renderMethod(
    <Client />,
    document.getElementById('root')
  )
})


if (module.hot) {
  module.hot.accept();
}

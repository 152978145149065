const mapWaysToBuy = response => {

    const { SeccionComoComprar } = response.homBuses[0]

    return SeccionComoComprar
            .ItemComoComprar
            .filter(x => x.imagen.activo)
            .map(x => {
                const { alternativeText, caption } = x.imagen.imagen[0];
                return {
                    link: x.link,
                    detail: x.descripcion,
                    image: x.imagen.imagen.map(x=>x.url),
                    imageAlt: alternativeText,
                    imgTitle: caption,
                }
            })
}


export default mapWaysToBuy
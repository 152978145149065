const domain = process.env.Domain,
    publicPath = process.env.PUBLIC_PATH,
    cloudFrontPath = process.env.CLOUDFRONT_PATH,
    version = process.env.Version;

const getPublicPath = path => `${publicPath}${path}?v=${version}`.toLowerCase();

const getCloudFrontPath = path => `${cloudFrontPath}${path}?v=${version}`.toLowerCase();

const getUrlParameter = name => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getDomain = country => `${domain}.${country}`;

const getFullCurrentDomain = () => `${window.location.protocol}//${window.location.hostname}`;

const toSEOFriendly = text => {
    return text 
        ? text.toLowerCase()
            .replace("'", "")
            .replace(/[á|ã|Á|À]/gi, "a")
            .replace(/[é|ê|É|È]/gi, "e")
            .replace(/[í|î|Í|Í]/gi, "i")
            .replace(/[ú|Ù|Ú]/gi, "u")
            .replace(/[ó|Ó]/gi, "o")
            .replace(/[ñ|Ñ]/gi, "n")
            .replace(/[ç]/gi, "c")

            // replace & with and
            .replace("&", "and")

            // remove invalid characters
            .replace(/[^a-z0-9]/gi, "-")

            // remove duplicates
            .replace(/-+/, "-")

            // trim leading & trailing characters
            .trim("-")

            .replace(/-+/g, "-")
        : '';
}

export default {
    domain,
    getDomain,
    getPublicPath,
    getCloudFrontPath,
    getUrlParameter,
    toSEOFriendly,
    getFullCurrentDomain
}
import getFirst from './getFirst';

const mapNews = data => {
    if(!data) return null;
    const { titulo,link,imagenNovedadPrincipal,tituloNovedadPrincipal,
        descripcionNovedadPrincipal,linkNovedadPrincipal,tituloNovedadSecundaria,
        descripcionNovedadSecundaria,linkNovedadesSecundaria } = data;
    
    const { imagen } = imagenNovedadPrincipal;

    return {
        titleLink: link,
        title: titulo,
        news: [{
            link: linkNovedadPrincipal,
            title: tituloNovedadPrincipal,
            description: descripcionNovedadPrincipal,
            image: imagen.map(x =>x.url),
            imageAlt: imagen.alternativeText,
            imageTitle: imagen.caption
        },
        {
            link: linkNovedadesSecundaria,
            title: tituloNovedadSecundaria,
            description: descripcionNovedadSecundaria,  
        }]
    }
}

const mapDeals = data => {
    if(!data) return null;

    const {
        titulo,
        link,
        detalleSeccion1,
        imagenSeccion1,
        linkPromocion1,
        detalleSeccion2,
        imagenSeccion2,
        linkPromocion2,
    } = data

    return {
        title: titulo,
        titleLink: link,
        items: [
            {
                link: linkPromocion1,
                imageAlt: imagenSeccion1[0]?.alternativeText,
                content: detalleSeccion1,
                image: imagenSeccion1[0]?.url  
            },
            {
                link: linkPromocion2,
                imageAlt: imagenSeccion2[0]?.alternativeText,
                content: detalleSeccion2,
                image: imagenSeccion2[0]?.url
            },
        ]
    }
}

const mapSpecialTrips = data => {
    if(!data) return null;
    
    const {
        titulo,
        link,
        tituloDetalle,
        detalle,
        imagen
    } = data

    return { 
        trips: {
            titleLink: link,
            imageTitle: imagen[0].caption,
            imageAlt: imagen[0].alternativeText,
            detail: detalle,
            image: imagen.map(x => x.url),
            titleDetail: tituloDetalle,
            title: titulo
        }
    }
}

const mapNewsSection = data => {
    const {
        SeccionNovedades,
        SeccionPromocionesYBeneficios,
        SeccionViajesEspeciales
    } = getFirst(data, 'homBuses');

    return {
        news: mapNews(SeccionNovedades),
        deals: mapDeals(SeccionPromocionesYBeneficios) ,
        specialTrips: mapSpecialTrips(SeccionViajesEspeciales)
    }
}

export default mapNewsSection
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Skeleton } from 'antd';

import Image from 'components/shared/image';
import MyAccount from 'components/header/myAccount';
import Currency from 'components/header/leftContainer/currency';
import Country from 'components/header/leftContainer/country';
import Navbar from 'components/header/navbar';

import { useApplication } from 'hooks/application';

import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import './translations';

export default () => {
  useStyles(classes);
  const { t } = useTranslation('owHeader');
  const { app, features, countries, url } = useApplication();
  const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.sm})` });
  const { showHowToBuy } = app;
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setTimeout(1000);
    setReady(true);
  }, []);

  const links = (
    <>
      {/*isMobile && 
          <li>
            <Link to={`/${t('myPurchasesLink')}`}> { t('returnTicket') } </Link>
          </li>*/}
      {url && (
        <li>
          <a href={url}> {t('home')} </a>
        </li>
      )}
      {showHowToBuy && (
        <li>
          <Link to={`/${t('howToShopLink')}`}> {t('howToShop')} </Link>
        </li>
      )}
      <li>
        <Link to={`/${t('helpLink')}`}> {t('help')} </Link>
      </li>
    </>
  );

  return (
    <header>
      <div
        className={
          app.changeHeaderColor
            ? `${classes.container} ${classes.headerColor}`
            : classes.container
        }
      >
        <div>
          {url ? (
            <a href={url}>
              <Image
                image={app.logo.url}
                alt={app.logo.alt}
                customClasses={classes.logo}
              />
            </a>
          ) : (
            <Image
              image={app.logo.url}
              alt={app.logo.alt}
              customClasses={classes.logo}
            />
          )}
        </div>
        <div className={classes.rightContainer}>
          <div className={classes.dropdownsAndMyAccountContainer}>
          {ready ? (
              <div className={classes.dropdownsContainer}>
                {countries.length > 1 && <Country />}
                {features.enableCurrency && <Currency />}
              </div>
              ) : (
                <Skeleton active />
              )}
              {/*!isMobile && 
                <a className={classes.returnTicket} href={t('header:myPurchasesLink')}>
                  { t("header:returnTicket") }
                </a>*/}
              <MyAccount />
          </div>

          <div className={classes.navbar}>
            <Navbar>{links}</Navbar>
          </div>
        </div>
      </div>
    </header>
  );
};

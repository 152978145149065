// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/razzle/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ef98b2649d262cebfc7965a14c6edb20-scss{width:100%;height:100%}.a210830637cf344a1b7322bf091b4cb2-scss{display:flex;height:100%;position:relative;justify-content:center;align-items:center}\n", ""]);
// Exports
exports.locals = {
	"root": "ef98b2649d262cebfc7965a14c6edb20-scss",
	"content": "a210830637cf344a1b7322bf091b4cb2-scss"
};
module.exports = exports;

import React from 'react';

const st0 = {
    fill:'#fff'
}

const st1 = {
    fill:'none',
    stroke:'#fff',
    'strokeWidth':'0.5',
    'strokeLinecap':'round',
    'strokeMiterlimit':10
}

export default () => 
    <svg data-icon="eticket" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  x="30px" y="30px"viewBox="0 0 16 16" >
        <path style={st0} d="M10.079,5.896h-0.039c-0.139,0-0.252-0.113-0.252-0.252V4.646c0-0.139,0.113-0.252,0.252-0.252h0.039
            c0.139,0,0.252,0.113,0.252,0.252v0.998C10.331,5.783,10.218,5.896,10.079,5.896z"/>
        <path style={st0} d="M10.331,7.327V6.328c0-0.139-0.113-0.252-0.252-0.252h-0.039c-0.139,0-0.252,0.113-0.252,0.252v0.998
            c0,0.139,0.113,0.252,0.252,0.252h0.039C10.218,7.579,10.331,7.466,10.331,7.327z"/>
        <path style={st0} d="M10.331,9.009V8.011c0-0.139-0.113-0.252-0.252-0.252h-0.039c-0.139,0-0.252,0.113-0.252,0.252v0.998
            c0,0.139,0.113,0.252,0.252,0.252h0.039C10.218,9.262,10.331,9.149,10.331,9.009z"/>
        <path style={st0} d="M10.331,10.692V9.694c0-0.139-0.113-0.252-0.252-0.252h-0.039c-0.139,0-0.252,0.113-0.252,0.252v0.998
            c0,0.139,0.113,0.252,0.252,0.252h0.039C10.218,10.944,10.331,10.831,10.331,10.692z"/>
        <path style={st0} d="M10.331,12.374v-0.998c0-0.139-0.113-0.252-0.252-0.252h-0.039c-0.139,0-0.252,0.113-0.252,0.252v0.998
            c0,0.139,0.113,0.252,0.252,0.252h0.039C10.218,12.627,10.331,12.514,10.331,12.374z"/>
        <path style={st1} d="M9.373,12.353l-8.998-0.009v-1.879c1.036,0,1.865-0.88,1.865-1.955c0-1.086-0.829-1.966-1.865-1.966V4.665
            h8.998"/>
        <path style={st0} d="M3.49,9.718h5.503c0.233,0,0.422,0.189,0.422,0.422v0.193c0,0.233-0.189,0.422-0.422,0.422H3.49
            c-0.233,0-0.422-0.189-0.422-0.422V10.14C3.068,9.907,3.257,9.718,3.49,9.718z"/>
        <path style={st0} d="M3.49,6.263h3.661c0.233,0,0.422,0.189,0.422,0.422v0.193c0,0.233-0.189,0.422-0.422,0.422H3.49
            c-0.233,0-0.422-0.189-0.422-0.422V6.685C3.068,6.452,3.257,6.263,3.49,6.263z"/>
        <path style={st0} d="M8.531,6.263h0.403c0.266,0,0.481,0.215,0.481,0.481v0.075C9.415,7.085,9.2,7.3,8.935,7.3l-0.403,0
            c-0.266,0-0.481-0.215-0.481-0.481V6.744C8.051,6.479,8.266,6.263,8.531,6.263z"/>
        <path style={st0} d="M6.51,7.933h2.483c0.233,0,0.422,0.189,0.422,0.422v0.193c0,0.233-0.189,0.422-0.422,0.422H6.51
            c-0.233,0-0.422-0.189-0.422-0.422V8.355C6.088,8.122,6.277,7.933,6.51,7.933z"/>
        <path style={st0} d="M3.49,7.933h1.869c0.233,0,0.422,0.189,0.422,0.422v0.193c0,0.233-0.189,0.422-0.422,0.422H3.49
            c-0.233,0-0.422-0.189-0.422-0.422V8.355C3.068,8.122,3.257,7.933,3.49,7.933z"/>
        <path style={st1} d="M10.745,4.665h4.603c0.082,0,0.144,0.033,0.195,0.087s0.082,0.119,0.082,0.206v1.086
            c0,0.076-0.031,0.141-0.082,0.195s-0.113,0.076-0.195,0.076h-3.353v1.401h3.046c0.082,0,0.144,0.022,0.195,0.076
            c0.051,0.054,0.072,0.12,0.072,0.206v0.999c0,0.076-0.02,0.141-0.072,0.195C15.184,9.259,15.122,9.28,15.04,9.28h-3.046v1.423h3.354
            c0.082,0,0.144,0.033,0.195,0.087c0.051,0.054,0.082,0.119,0.082,0.206v1.086c0,0.076-0.031,0.141-0.082,0.195
            c-0.051,0.054-0.113,0.076-0.195,0.076h-4.603"/>
    </svg>

            
export default {
    howtoShop:'How to shop?',
    joinOurNet:'Join our Net',
    blog:'Blog',
    help:'Help',
    howtoShopLink:'how-to-shop',
    joinOurNetLink:'customer-service-center',
    blogLink:'blog',
    helpLink:'help',
    electronicLink:'how-to-show/eletronic',
    returnTicket: 'Return ticket',
    myPurchasesLink:'usuario/miscompras',
    attention: 'attention',
    returnLoginMessage: 'In order to return your tickets you must be logged in and then choose the tickets you wish to return.'
}
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import breakpoints from 'shared/breakpoints';

import { useApplication } from 'hooks/application';

import '../translations';

import HeaderAr from './headerAr';
import HeaderBr from './headerBr';
import HeaderPy from './headerPy';
import HeaderPe from './headerPe';

const HeaderManager = () => {
    const isMobile = useMediaQuery({ query: `(max-width:${breakpoints.sm})` });
    const { culture } = useApplication();

	const lng = culture.iso_code;

    const parameters = {
        isMobile,
        lng
    }

    return {
         getHeader: (country) =>{
            switch (country) {
                case 'ar':
                    return <HeaderAr parameters = {parameters}/>;
                case 'br':
                    return <HeaderBr parameters = {parameters}/>;
                case 'pe':
                    return <HeaderPe parameters = {parameters}/>;
                case 'py':
                    return <HeaderPy parameters = {parameters}/>;
                default:
                    return <HeaderAr parameters = {parameters}/>;
            }
        }
    }
    
}

export default HeaderManager

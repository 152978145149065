const mapTerminalComments = ({Comentarios})  => {

    return {
        comments: Comentarios?.map(comment => {
            const { Usuario, Fecha, Puntaje, Comentario } = comment;

            return {
                username: Usuario,
                date: Fecha,
                rating: Puntaje,
                text: Comentario
            }
        })
    }
};

export default mapTerminalComments;
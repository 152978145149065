import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Icon = ({ name, classes, onClick }) => 
{
    const [IconElement, setIconRender] = useState({ Icon: '' });

    useEffect(() => { 
        let isSubscribed = true;       
        const getIcon = () => {
            const {default: Icon} = require(`./${name}`);
            if(isSubscribed) setIconRender({Icon});
        };

        getIcon();

        return () => isSubscribed = false;
    }, [name]);

    return (
        <i className={classes} onClick={onClick}>
        {
            IconElement.Icon && <IconElement.Icon />
        }
        </i>
    )
}

Icon.propTypes = {
    name: PropTypes.string.isRequired
} 

export default Icon;
const mapQuestions = questions => {
    return questions.map(question => {
        return{
            title: question.TituloPreguntaFrecuente,
            description: question.DescripcionPreguntaFrecuente 
        }
    })  
    
};

export default ({ DescripcionTerceraParte, Metadata, UrlBotonSegundaParte }, { Preguntas }) => ({
    metadata: Metadata,
    hours: DescripcionTerceraParte,
    questions: mapQuestions(Preguntas),
    buttonUrl: UrlBotonSegundaParte
});
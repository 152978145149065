import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Icon from 'components/shared/icon';
import MenuMobile from 'components/header/menuMobile';

import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default ({ children }) => {
    useStyles(classes, globalStyles);
	  const [menuOpen, setMenuOpen] = useState(false);
    const isDesktop = useMediaQuery({ query: `(min-width:${breakpoints.md})`});

    return (
      isDesktop 
        ? <nav>
            <div className={`${classes.items} ${globalStyles.flexJustifyEnd}`}>
              <ul> { children } </ul>
            </div>
          </nav>
        : <>
            <div 
              className={globalStyles.flexJustifyEnd} 
              onClick={() => setMenuOpen(!menuOpen)} 
            >
              <button aria-label='menu' className={classes.button}>
                <Icon classes={classes.iconMenu} name='menu' />
              </button>
            </div>
            {
              menuOpen && 
                <MenuMobile>
                  { children }
                </MenuMobile> 
              }
          </>
  )
}
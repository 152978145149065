import i18next  from 'i18next';

import es from './es';
import pt from './pt';
import en from './en';
import pe from './pe';

i18next.addResourceBundle('es', 'links', es);
i18next.addResourceBundle('en', 'links', en);
i18next.addResourceBundle('es-PE', 'links', pe);
i18next.addResourceBundle('pt', 'links', pt);
const mapImages = images => {
    return images.map(image => {
        const { ImgAlt, UrlImagen } = image;

        return {
            alt: ImgAlt,
            url: UrlImagen
        }
    })
}

export default data => {
    const { 
        TituloDebito, TituloCredito, Titulo, TextoSuperiorDebito, TextoSuperiorCredito,
        TextoInferiorDebito, TextoInferiorCredito, TextoAdvertenciaDebito, Subtitulo,
        ImagenesTarjetas, Metadata
    } = data.CompraElectronicaViewModel;

    return {
        title: Titulo,
        subtitle: Subtitulo,
        debitTitle: TituloDebito,
        creditTitle: TituloCredito,
        debitTopText: TextoSuperiorDebito,
        creditTopText: TextoSuperiorCredito,
        debitBottomText: TextoInferiorDebito,
        creditBottomText: TextoInferiorCredito,
        warningDebitText: TextoAdvertenciaDebito,
        images: mapImages(ImagenesTarjetas),
        metadata: Metadata
    }
};
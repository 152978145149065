import React, { useEffect, useState } from 'react';

import Image from 'components/shared/image';

import useContent from 'hooks/content';

import useStyles from 'isomorphic-style-loader/useStyles';
import globalStyles from 'styles/main.scss';

export default () => {
  useStyles(globalStyles);
  const { getCertificates } = useContent();

  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await getCertificates();
      setCertificates(data);
    }

    getData();
  }, []);
  
  return (
    <div className={globalStyles.flexJustifyCenter}>
      {
        certificates.map((cert, index) => {
            const {link, image, alt} = cert.item;

            return (
                <div key={index} className={globalStyles.mlr10}>
                  <a href={link}>
                      <img src={image} alt={alt} />
                  </a>
                </div>
            );
        })
      }
    </div>
  )
}
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import MenuMobile from 'components/header/menuMobile';

import urls from 'shared/urls';
import breakpoints from 'shared/breakpoints';

import useStyles from 'isomorphic-style-loader/useStyles';
import classes from './style.module.scss';
import globalStyles from 'styles/main.scss';

export default ({ data, onClick, dropdownTitle, property,renderBorder=true }) => {
    useStyles(classes, globalStyles);
    const [isOpen, setIsOpen] = useState(false);
    const isDesktop = useMediaQuery({ query: `(min-width:${breakpoints.md})`});
    
    const handleMouseOver = () => setIsOpen(true);
    const handleMouseLeave = () => setIsOpen(false);

    const handleClick = () => setIsOpen(prevState => !prevState);

    const Links = () => (
        <>
            {
                data.map(item => {
                    const value = item[property].toLowerCase();

                    return (
                        <li key={value} onClick={() => onClick(item)} className={classes.countries}>
                            <div className={classes.container}>
                                <div>
                                    <img 
                                        src={urls.getPublicPath(`images/home/dropdown-${value}.png`)} 
                                        title={value} 
                                        alt={value} 
                                        width='20' 
                                        height='20' 
                                    />
                                </div>

                                <div>
                                    <span className={classes.text} >
                                        { value }
                                    </span>
                                </div>
                            </div>
                        </li>
                    )
                })
            }
        </>
    )

    return (
        data.length > 0 
            ? <div
                className={classes.dropdownContainer}
                onMouseOver={isDesktop ? handleMouseOver : null}
                onMouseLeave={isDesktop ? handleMouseLeave : null}
                onClick={handleClick}
                data-testid='container'
              >
                <div className={globalStyles.flexColumn}>
                    <div className={classes.circleContainer}>
                        <div className={renderBorder? classes.circleBorder :classes.circle}>
                            <img 
                                src={urls.getPublicPath(`images/home/dropdown-${dropdownTitle}.png`)} 
                                alt={dropdownTitle} 
                                title={dropdownTitle} 
                                width='20'
                                height='20' 
                            />
                        </div>
                        
                       {(isDesktop || !renderBorder) ? <span className={classes.dropdownTitle}> { dropdownTitle } </span>: <></>}
                        { 
                            !isDesktop && isOpen && 
                                <MenuMobile data-testid='menu-mobile'>
                                    <Links />
                                </MenuMobile>
                        }   
                    </div>
                    { 
                        isDesktop && 
                            <div className={classes.dropdownBox} style={{ display: isOpen ? 'block' : 'none' }}>
                                <ul>
                                    <Links />
                                </ul>
                            </div>
                    }
                </div>
              </div>
            : null
    )
}